import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import {jwtDecode} from "jwt-decode"
import OnboardingPage from "./account/OnboardingPage";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: ${({ theme }) => theme.body};
`;

const LoginBox = styled.div`
  padding: 40px;
  background-color: ${({ theme }) => theme.body};
  border-radius: 10px;
  max-width: 400px;
  width: 100%;
`;

const Title = styled.h2`
  margin-bottom: 20px;
  color: ${({ theme }) => theme.text};
  text-align: center;
`;

const Input = styled.input`
  width: 100%;
  padding: 15px;
  font-size: 1rem;
  border: 1px solid ${({ theme }) => theme.inputBorder};
  border-radius: 5px;
  color: ${({ theme }) => theme.text};
  margin-bottom: 20px;
  box-sizing: border-box;
`;

const Button = styled.button`
  width: 100%;
  padding: 15px;
  background-color: ${({ theme }) => theme.post_color};
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  margin-bottom: 20px;
  box-sizing: border-box;

  &:hover {
    background-color: ${({ theme }) => theme.post_hover};
  }
`;

const SwitchFormText = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.secondaryText};
  margin-top: 10px;

  a {
    color: ${({ theme }) => theme.primary};
    cursor: pointer;
    text-decoration: none;
  }
`;

const ErrorMessage = styled.p`
  margin-top: 0px;
  text-align: center;
  color: red;
`;

const Logo = styled.img`
  position: fixed;
  top: 59px; /* Adjust as needed */
  height: 50px;
  z-index: 10;
`;

const Login = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [userName, setUserName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSignUp, setIsSignUp] = useState(false); // Track if the user is signing up
  const navigate = useNavigate();

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const validateFields = () => {
    if (!email || !password) {
      setErrorMessage('Please fill out all fields.');
      return false;
    }
    if (!validateEmail(email)) {
      setErrorMessage('Please enter a valid email address.');
      return false;
    }
    if (password.length < 6) {
      setErrorMessage('Password must be at least 6 characters.');
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateFields()) return;
  
    const url = isSignUp
      ? 'https://platform.iwander.io/external/user-creation'
      : 'https://platform.iwander.io/external/verify-login';
  
    const payload = isSignUp
      ? {
          user_email: email,
          user_name: null,
          password,
          provider: 'email_password',
        }
      : { user_email: email, user_name: null, password, provider: 'email_password' };
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });
  
      const data = await response.json();
  
      if (response.ok) {
  
        onLogin(data.token);
  
        if (data.organisation_id) {
          localStorage.setItem('authToken', data.token);
          localStorage.setItem('organisation_id', data.organisation_id); 
          localStorage.setItem('subscription_status', data.subscription_status); 
          localStorage.setItem('user_name', data.user_name || ''); 
          navigate('/docs/api-reference')
        
        } else {
          localStorage.setItem('authToken', data.token);
          localStorage.setItem('organisation_id', data.organisation_id);  
          localStorage.setItem('subscription_status', data.subscription_status); 
          localStorage.setItem('user_name', data.user_name || ''); 
          navigate('/onboarding')
        } 
        } else {
        setErrorMessage(data.detail || 'Submission failed');
      }
    } catch {
      setErrorMessage('An error occurred. Please try again.');
    }
  };
  
  const handleGoogleAuth = async (credentialResponse) => {
    try {
      const decoded = jwtDecode(credentialResponse.credential);
      const url = isSignUp
        ? 'https://platform.iwander.io/external/user-creation'
        : 'https://platform.iwander.io/external/verify-login';
  
      const payload = {
        user_email: decoded.email,
        user_name: decoded.name,
        password: '',
        provider: 'google_auth',
      };
  
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });
  
      const data = await response.json();
  
      if (response.ok) {
  
        onLogin(data.token);
    
        if (data.organisation_id) {
          localStorage.setItem('authToken', data.token);
          localStorage.setItem('organisation_id', data.organisation_id); 
          localStorage.setItem('subscription_status', data.subscription_status); 
          localStorage.setItem('user_name', data.user_name || ''); 
          navigate('/docs/api-reference')
        
        } else {
          localStorage.setItem('authToken', data.token);
          localStorage.setItem('organisation_id', data.organisation_id);  
          localStorage.setItem('subscription_status', data.subscription_status); 
          localStorage.setItem('user_name', data.user_name || ''); 
          navigate('/onboarding')
        } 
        } else {
        setErrorMessage(data.detail || 'Submission failed');
      }
    } catch {
      setErrorMessage('An error occurred with Google login.');
    }
  };
  
  const logoSrc = `${process.env.PUBLIC_URL}/images/logo_black.png`;

  return (
    <Container>
      <Logo src={logoSrc} alt="Logo" />
      <LoginBox>
        <Title>{isSignUp ? 'Sign Up' : 'Login'}</Title>

        <Input
          type="email"
          placeholder="Enter email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyDown={(e) => e.key === 'Enter' && handleSubmit()}
        />
        <Input
          type="password"
          placeholder="Enter password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={(e) => e.key === 'Enter' && handleSubmit()}
        />

        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

        <Button onClick={handleSubmit}>
          {isSignUp ? 'Create Account' : 'Login'}
        </Button>

        <SwitchFormText>
          {isSignUp ? (
            <>
              Already have an account?{' '}
              <a onClick={() => setIsSignUp(false)}>Login</a>
            </>
          ) : (
            <>
              Don't have an account?{' '}
              <a onClick={() => setIsSignUp(true)}>Sign Up</a>
            </>
          )}
        </SwitchFormText>
        <GoogleLogin
          onSuccess={handleGoogleAuth}
          onError={() => setErrorMessage('Google login failed.')}
        />

      </LoginBox>
    </Container>
  );
};

export default Login;
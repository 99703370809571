import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button, TextField, Switch, FormControlLabel, Slider, RadioGroup, FormControl, Radio, MenuItem, Select, InputLabel, Chip, Box } from "@mui/material";

const StyledTextField = styled(TextField)(({ theme }) => ({
    "& .MuiOutlinedInput-root": {
        color: theme.text, // Set the text color
        fontSize: "12px",
        "& fieldset": {
            borderColor: theme.border, // Set the border color
        },
        "&:hover fieldset": {
            borderColor: theme.primary, // Set the hover border color
        },
        "&.Mui-focused fieldset": {
            borderColor: theme.primary, // Set the focus border color
        },
    },
    "& .MuiInputBase-input": {
        color: theme.text, // Set the input text color
    },
}));

const PageContainer = styled.div`
  display: flex;
  height: 100vh;
  color: ${({ theme }) => theme.text};
  font-size: 12px !important;
`;

const DetailsPanel = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
`;

const SectionTitle = styled.h3`
  margin-top: 10px;
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.secondaryText};
`;

const ToolContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px 0;
`;

const ButtonGroup = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 10px;
`;

const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.primary} !important;
  color: ${({ theme }) => theme.text} !important;

  &:hover {
    background-color: ${({ theme }) => theme.hover} !important;
  }
`;


const InputContainer = styled.div`
  margin: 10px 0;
`;

const SliderContainer = styled.div`
  color: ${({ theme }) => theme.text};
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
`;

const IndividualSlider = styled.div`
  color: ${({ theme }) => theme.text};
  padding: 20px;
  border-radius: 8px;
  width: calc(50% - 50px);
  box-sizing: border-box;
`;

const StyledSlider = styled(Slider)`
  & .MuiSlider-thumb {
    color: ${({ theme }) => theme.thumb};
    border: 2px solid ${({ theme }) => theme.text};
  }
  & .MuiSlider-track {
    color: ${({ theme }) => theme.primary};
  }
  & .MuiSlider-rail {
    color: ${({ theme }) => theme.border};
  }
  & .MuiSlider-markLabel {
    color: ${({ theme }) => theme.text};
    font-size: 12px;
  }
`;

const ChipListContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 10px;
`;


const PostAgentSetup = () => {
    const [selectedAssistant, setSelectedAssistant] = useState({ id: "1", name: "Post Booking" });
    const [name, setName] = useState("");
    const [orgName, setOrgName] = useState("");
    const [description, setDescription] = useState("");
    const [error, setError] = useState('');
    const [model, setModel] = useState("attractions");
    const [hotels, setHotels] = useState(false);
    const [attractions, setAttractions] = useState(false);
    const [flights, setFlights] = useState(false);
    const [experiences, setExperiences] = useState(false);
    const [friendly, setFriendly] = useState(1); // 1 is Medium by default
    const [empathy, setEmpathy] = useState(1); // 1 is Medium by default
    const [factual, setFactual] = useState(1); // 1 is Medium by default
    const [energetic, setEnergetic] = useState(1); // 1 is Medium by default
    const [length, setLength] = useState(1); // 1 is Medium by default
    const [depth, setDepth] = useState(1); // 1 is Medium by default
    const [enthusiasm, setEnthusiasm] = useState(1); // 1 is Medium by default
    const [detail, setDetail] = useState(1); // 1 is Medium by default
    const [ticketScope, setTicketScope] = useState("global"); // 'global' or 'specific'
    const [newLocation, setNewLocation] = useState(''); // Tracks input in the TextField
    const [locations, setLocations] = useState([]);

    useEffect(() => {
        const fetchPostBookConfig = async () => {
            const token = localStorage.getItem('authToken');
            if (!token) {
                console.error('No token found');
                return;
            }

            try {
                const response = await fetch('https://platform.iwander.io/agent_setup/get-post-booking-config', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch API keys');
                }

                const data = await response.json();
                setOrgName(data.post_booking_agent.organisation_name);
                setDescription(data.post_booking_agent.organisation_description);
                setName(data.post_booking_agent.guide_name);
                setTicketScope(data.post_booking_agent.ticket_scope)
                setLocations(data.post_booking_agent.locations)
                setFriendly(data.post_booking_agent.tone_of_voice.friendly)
                setEmpathy(data.post_booking_agent.tone_of_voice.empathy)
                setFactual(data.post_booking_agent.tone_of_voice.factual)
                setEnergetic(data.post_booking_agent.tone_of_voice.energetic)
                setLength(data.post_booking_agent.tone_of_voice.length)
                setDepth(data.post_booking_agent.tone_of_voice.depth)
                setEnthusiasm(data.post_booking_agent.tone_of_voice.enthusiasm)
                setDetail(data.post_booking_agent.tone_of_voice.detail)
                const purposes = data.post_booking_agent.purpose || [];
                setAttractions(purposes.includes('attractions'));
                setHotels(purposes.includes('hotels'));
                setFlights(purposes.includes('flights'));
                setExperiences(purposes.includes('experiences'));
            } catch (error) {
                console.error('Error fetching API keys:', error);
            }
        };

        fetchPostBookConfig();
    }, []);

    const professionalMarks = [
        { value: 0, label: "Professional" },
        { value: 1, label: "Neutral" },
        { value: 2, label: "Friendly" },
    ];
    const empathyMarks = [
        { value: 0, label: "Empathetic" },
        { value: 1, label: "Neutral" },
        { value: 2, label: "Direct" },
    ];
    const factMarks = [
        { value: 0, label: "Factual" },
        { value: 1, label: "Neutral" },
        { value: 2, label: "Inspirational" },
    ];
    const energyMarks = [
        { value: 0, label: "Calm" },
        { value: 1, label: "Neutral" },
        { value: 2, label: "Energetic" },
    ];

    const lengthMarks = [
        { value: 0, label: "Short" },
        { value: 1, label: "Medium" },
        { value: 2, label: "Long" },
    ];
    const depthMarks = [
        { value: 0, label: "Minimal" },
        { value: 1, label: "Balanced" },
        { value: 2, label: "Comprehensive" },
    ];
    const enthusiasMarks = [
        { value: 0, label: "Low" },
        { value: 1, label: "Moderate" },
        { value: 2, label: "High" },
    ];
    const detailMarks = [
        { value: 0, label: "High-Level" },
        { value: 1, label: "Moderate" },
        { value: 2, label: "In-Depth" },
    ];

    const availableLocations = [
        'New York', 'London', 'Paris', 'Tokyo', 'Sydney', 'Los Angeles', 'Berlin', 'Rome'
    ];

    const handleScopeChange = (e) => {
        setTicketScope(e.target.value);
        setLocations([]); // Clear locations when scope changes
    };

    // Handle text input changes
    const handleInputChange = (e) => {
        setNewLocation(e.target.value);
    };

    // Add location on Enter key press
    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && newLocation.trim() !== '') {
            // Add location if not already in the list
            if (!locations.includes(newLocation.trim())) {
                setLocations([...locations, newLocation.trim()]);
            }
            setNewLocation(''); // Clear the input field
        }
    };

    // Remove a location
    const handleDelete = (locationToDelete) => {
        setLocations(locations.filter((loc) => loc !== locationToDelete));
    };

    const handleChange = (e) => {
        const value = e.target.value;
        if (value.length <= 100) {
            setDescription(value);
            console.log(description)
            setError(''); // Clear error if input is valid
        } else {
            setError('Description must be 100 characters or less');
        }
    };

    const handleSaveChanges = async () => {
        // Construct the payload
        const payload = {
            "post_booking":
            {
                organisation_name: orgName,
                organisation_description: description,
                guide_name: name,
                purpose: [],
                ticket_scope: ticketScope,
                locations: locations,
                tone_of_voice: {
                    friendly,
                    empathy,
                    factual,
                    energetic,
                    length,
                    depth,
                    enthusiasm,
                    detail,
                },
            }
        };

        // Add selected purpose items based on the switches
        if (attractions) payload.post_booking.purpose.push("attractions");
        if (hotels) payload.pre_booking.purpose.push("hotels");
        if (flights) payload.post_booking.purpose.push("flights");
        if (experiences) payload.post_booking.purpose.push("experiences");

        try {
            // Send the payload to the API
            const token = localStorage.getItem("authToken");
            const response = await fetch("https://platform.iwander.io/agent_setup/update-post-booking-config", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error("Failed to update configuration");
            }

            // alert("Configuration updated successfully!");
        } catch (error) {
            console.error("Error updating configuration:", error);
            alert("Failed to update configuration. Please try again.");
        }
    };

    return (
        <PageContainer>
            {/* Details Panel */}
            <DetailsPanel>
                {selectedAssistant ? (
                    <>
                        <h3>{selectedAssistant.name}</h3>
                        <InputContainer>
                            <SectionTitle>Organisation Name</SectionTitle>
                            <StyledTextField
                                fullWidth
                                placeholder="Name of your organisation"
                                value={orgName}
                                onChange={(e) => setOrgName(e.target.value)}
                                variant="outlined"
                                size="small"
                            />
                        </InputContainer>

                        <InputContainer>
                            <SectionTitle>Organisation Description</SectionTitle>
                            <StyledTextField
                                fullWidth
                                placeholder="Enter description (max 100 characters)"
                                value={description}
                                onChange={handleChange}
                                variant="outlined"
                                size="small"
                            />
                        </InputContainer>

                        <InputContainer>
                            <SectionTitle>Guide Name</SectionTitle>
                            <StyledTextField
                                fullWidth
                                placeholder="Enter the name of your guide"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                variant="outlined"
                                size="small"
                            />
                        </InputContainer>

                        {/* <InputContainer>
                            <SectionTitle>Type</SectionTitle>
                            <Select
                                value={model}
                                onChange={(e) => setModel(e.target.value)}
                                fullWidth
                                style={{ color: "#fff", backgroundColor: "#3f3f4b" }}
                            >
                                <MenuItem value="attractions">attractions</MenuItem>
                                <MenuItem value="hotels">hotels</MenuItem>
                            </Select>
                        </InputContainer> */}

                        <InputContainer>
                            <SectionTitle>Ticket Scope</SectionTitle>
                            <FormControl component="fieldset">
                                <RadioGroup row value={ticketScope} onChange={handleScopeChange}>
                                    <FormControlLabel
                                        value="global"
                                        control={<Radio />}
                                        label="Global"
                                        sx={{
                                            '& .MuiFormControlLabel-label': {
                                                fontSize: '14px', // Adjust the font size
                                            },
                                        }}
                                    />
                                    <FormControlLabel
                                        value="specific"
                                        control={<Radio />}
                                        label="Specific Regions or Cities"
                                        sx={{
                                            '& .MuiFormControlLabel-label': {
                                                fontSize: '14px', // Adjust the font size
                                            },
                                        }}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </InputContainer>

                        {/* Text Input for Adding Destinations */}
                        {ticketScope === 'specific' && (
                            <InputContainer>
                                <SectionTitle>Enter Destinations</SectionTitle>
                                <StyledTextField
                                    fullWidth
                                    placeholder="Type a city or region and press Enter..."
                                    value={newLocation}
                                    onChange={handleInputChange}
                                    onKeyPress={handleKeyPress}
                                    variant="outlined"
                                    size="small"
                                />

                                {/* List of Added Destinations */}
                                <ChipListContainer>
                                    {locations.map((location, index) => (
                                        <Chip
                                            key={index}
                                            label={location}
                                            onDelete={() => handleDelete(location)}
                                            color="primary"
                                        />
                                    ))}
                                </ChipListContainer>
                            </InputContainer>
                        )}

                        <ToolContainer>
                            <SectionTitle>Purpose</SectionTitle>
                            <FormControlLabel
                                style={{ marginLeft: '0px' }}
                                control={
                                    <Switch
                                        checked={attractions}
                                        onChange={(e) => setAttractions(e.target.checked)}
                                        color="primary"

                                    />
                                }
                                label="Attractions"
                                sx={{
                                    '& .MuiFormControlLabel-label': {
                                        fontSize: '14px', // Adjust the font size
                                    },
                                }}
                            />
                            <FormControlLabel
                                style={{ marginLeft: '0px' }}
                                control={
                                    <Switch
                                        checked={hotels}
                                        onChange={(e) => setHotels(e.target.checked)}
                                        color="primary"
                                    />
                                }
                                label="Hotels"
                                sx={{
                                    '& .MuiFormControlLabel-label': {
                                        fontSize: '14px', // Adjust the font size
                                    },
                                }}

                            />
                            <FormControlLabel
                                style={{ marginLeft: '0px' }}
                                control={
                                    <Switch
                                        checked={flights}
                                        onChange={(e) => setFlights(e.target.checked)}
                                        color="primary"
                                    />
                                }
                                label="Flights"
                                sx={{
                                    '& .MuiFormControlLabel-label': {
                                        fontSize: '14px', // Adjust the font size
                                    },
                                }}

                            />
                            <FormControlLabel
                                style={{ marginLeft: '0px' }}
                                control={
                                    <Switch
                                        checked={experiences}
                                        onChange={(e) => setExperiences(e.target.checked)}
                                        color="primary"
                                    />
                                }
                                label="Experiences"
                                sx={{
                                    '& .MuiFormControlLabel-label': {
                                        fontSize: '14px', // Adjust the font size
                                    },
                                }}

                            />
                        </ToolContainer>
                        <SectionTitle>Tone of Voice</SectionTitle>

                        <SliderContainer>

                            {/* Temperature Slider */}
                            <IndividualSlider>
                                Professional vs. Friendly

                                <StyledSlider
                                    value={friendly}
                                    onChange={(e, newValue) => setFriendly(newValue)}
                                    step={1}
                                    marks={professionalMarks}
                                    min={0}
                                    max={2}
                                />
                            </IndividualSlider>


                            {/* Top P Slider */}
                            <IndividualSlider>
                                Empathetic vs. Neutral

                                <StyledSlider
                                    value={empathy}
                                    onChange={(e, newValue) => setEmpathy(newValue)}
                                    step={1}
                                    marks={empathyMarks}
                                    min={0}
                                    max={2}
                                />
                            </IndividualSlider>
                            <IndividualSlider>
                                Factual vs. Inspirational

                                <StyledSlider
                                    value={factual}
                                    onChange={(e, newValue) => setFactual(newValue)}
                                    step={1}
                                    marks={factMarks}
                                    min={0}
                                    max={2}
                                />
                            </IndividualSlider>
                            <IndividualSlider>
                                Calm vs. Energetic

                                <StyledSlider
                                    value={energetic}
                                    onChange={(e, newValue) => setEnergetic(newValue)}
                                    step={1}
                                    marks={energyMarks}
                                    min={0}
                                    max={2}
                                />
                            </IndividualSlider>
                            <IndividualSlider>
                                Answer Length

                                <StyledSlider
                                    value={length}
                                    onChange={(e, newValue) => setLength(newValue)}
                                    step={1}
                                    marks={lengthMarks}
                                    min={0}
                                    max={2}
                                />
                            </IndividualSlider>
                            <IndividualSlider>
                                Depth of Recommendations

                                <StyledSlider
                                    value={depth}
                                    onChange={(e, newValue) => setDepth(newValue)}
                                    step={1}
                                    marks={depthMarks}
                                    min={0}
                                    max={2}
                                />
                            </IndividualSlider>
                            <IndividualSlider>
                                Level of Enthusiasm

                                <StyledSlider
                                    value={enthusiasm}
                                    onChange={(e, newValue) => setEnthusiasm(newValue)}
                                    step={1}
                                    marks={enthusiasMarks}
                                    min={0}
                                    max={2}
                                />
                            </IndividualSlider>
                            <IndividualSlider>
                                Detail Depth

                                <StyledSlider
                                    value={detail}
                                    onChange={(e, newValue) => setDetail(newValue)}
                                    step={1}
                                    marks={detailMarks}
                                    min={0}
                                    max={2}
                                />
                            </IndividualSlider>

                        </SliderContainer>

                        <ButtonGroup>
                            {/* <StyledButton variant="outlined">Reset</StyledButton> */}
                            <StyledButton variant="contained" onClick={handleSaveChanges}>Save Changes</StyledButton>
                        </ButtonGroup>
                    </>
                ) : (
                    <h3>Select an assistant to view details</h3>
                )}
            </DetailsPanel>
        </PageContainer>
    );
}
export default PostAgentSetup;
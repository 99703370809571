import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Heatmap from './Heatmap';
import TimeFilter from "./TimeFilter";
import APIRequestsGraphWithCost from './GraphContainer';
import DetailedTable from './DetailedTable';
import ActiveUsersTable from './ActiveUsersTable';
import AllUsersTable from './UsersTable';
import Header from '../Header';
import { Tabs, Tab, Box } from '@mui/material';

const DashboardContainer = styled.div`
  display: flex;
  height: 90vh;
  overflow: hidden;
  margin-top: 54px;
  margin-left: 8px;
  margin-right: 8px;
  color: ${({ theme }) => theme.text};
`;

const Sidebar = styled.div`
  display: flex;
  flex-direction: column; /* Stack tabs vertically */
  width: 200px; /* Fixed width for the sidebar */
  padding: 14px 0;
  font-size: 14px;
  z-index: 101;
`;

const StyledTab = styled(Tab)`
  color: ${({ theme }) => theme.secondaryText} !important;
  font-size: 14px;
  text-transform: none !important;
  font-family: Montserrat;
  align-items: flex-start !important; /* Align text to the left */
  justify-content: flex-start !important; /* Align text to the left */

  &:hover {
    color: ${({ theme }) => theme.text} !important;
  }

  &.MuiTab-root {
    font-family: Montserrat;
    font-weight: 500;
  }

  &.Mui-selected {
    color: ${({ theme }) => theme.primary} !important;
    background-color: ${({ theme }) => theme.inputBg} !important;
    font-weight: bold !important;
    font-family: Montserrat;
  }
`;

const TabDiv = styled.div`
  padding-top: 12px;
  padding-bottom: 16px;
  overflow: auto;
`;


const SidebarTitle = styled.div`
  padding-right: 16px;
  padding-left: 16px;
  margin: 15px 0px;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
`;

const ContentArea = styled.div`
  flex: 1;
  padding: 20px;
  background: ${({ theme }) => theme.inputBg};
  border-radius: 10px;
  overflow: scroll;
  border: 1px solid;
  border-color: ${({ theme }) => theme.borderColor};
`;

const MonthFilter = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ArrowButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.primary};
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.text} !important; 
  }
`;

const ExportButton = styled.button`
  background-color: ${({ theme }) => theme.primary};
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.primaryHover};
  }
`;

const TabContent = styled.div`
  margin-top: 20px;
  height: 70vh;
`;

function Dashboard({ toggleTheme, theme }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  // Extract the active tab from the query parameter, default to "overview"
  const currentTab = searchParams.get('tab') || 'overview';
  const [activeTab, setActiveTab] = useState(currentTab);

  const [chartData, setChartData] = useState(null);
  const [totalCost, setTotalCost] = useState(0);
  const [chatCost, setChatCost] = useState(0);
  const [audioCost, setAudioCost] = useState(0);
  const [totalChatRequests, setTotalChatRequests] = useState(0);
  const [totalAudioRequests, setTotalAudioRequests] = useState(0);
  const [costPercentage, setCostPercentage] = useState(0);
  const [detailedData, setDetailedData] = useState([]);
  const [activeUsersData, setActiveUsersData] = useState([]);
  const [heatmapData, setHeatmapData] = useState([]);

  const [timeRange, setTimeRange] = useState({
    start_time: new Date(new Date().setDate(new Date().getDate() - 7)).toISOString(),
    end_time: new Date().toISOString(),
  });
  const { start_time, end_time } = timeRange;
  console.log(timeRange)
  const handleTimeFilterChange = (range) => {
    setTimeRange(range);
    // Trigger data fetch here if needed
  };
  
  useEffect(() => {
    // Fetch data once when the dashboard initializes
    const fetchData = async () => {
      const organisationId = localStorage.getItem('organisation_id'); 
      try {
        const response = await fetch('https://platform.iwander.io/internal/organisation-activity', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ start_time, end_time, organisation_id:organisationId }),

        });
        const data = await response.json();

        const dailyRequests = data.daily_requests; // List of daily requests
        const totals = data.totals; // Total chat and audio requests
        const costs = data.cost; // Cost for chat and audio requests
        const detailedResponse = data.detailed_tab
        const activeUsersResponse = data.active_users
        const heatmapResponse = data.heatmap_tab

        // Map daily requests for graph processing
        const dates = dailyRequests.map((item) => item.date);
        const chatRequests = dailyRequests.map((item) => item.requests.chat);
        const audioRequests = dailyRequests.map((item) => item.requests.audio);

        // Set total costs and cost percentage
        setChatCost(costs.chat.toFixed(2)); // Chat cost from the response
        setAudioCost(costs.audio.toFixed(2)); // Audio cost from the response
        setTotalCost((costs.chat + costs.audio).toFixed(2)); // Total cost
        setCostPercentage(((costs.chat / (costs.chat + costs.audio || 1)) * 100).toFixed(2)); // Cost percentage of chat to total

        // Set totals
        setTotalChatRequests(totals.chat);
        setTotalAudioRequests(totals.audio);

        // Create the chart data
        setChartData({
          labels: dates,
          datasets: [
            {
              label: 'Chat Requests',
              data: chatRequests,
              backgroundColor: 'rgba(75, 192, 192, 0.6)', // Chat bar color
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1,
            },
            {
              label: 'Audio Requests',
              data: audioRequests,
              backgroundColor: 'rgba(255, 159, 64, 0.6)', // Audio bar color
              borderColor: 'rgba(255, 159, 64, 1)',
              borderWidth: 1,
            },
          ],
        });

        const transformedData = detailedResponse.map((item) => ({
          guide: item.guide,
          user_id: item.user_id,
          request_location: item.request_location,
          input: item.human_message,
          output: item.messages,
          time: new Date(item.time).toLocaleString('en-GB', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
              hour12: false, // Use 24-hour format
          }),
          metadata: {
              user_id: item.user_id,
              organisation_id: item.api_key,
              time: new Date(item.time).toLocaleString('en-GB', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                  hour12: false, // Use 24-hour format
              }),
              request_location: item.request_location,
              language: item.language,
              guide: item.guide,
              context: item.context,
              message_id: item.message_id,
              session_id: item.session_id,
              // query_location: item.query_location,
              coordinates: item.coordinates,
              // audio: item.audio,
              // liked: item.liked,
          },
      }));
      setDetailedData(transformedData);

      const activeusers = activeUsersResponse.map((item) => ({
        api_key: item.api_key,
        latest_updated: new Date(item.latest_updated).toLocaleString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false, // Use 24-hour format
        }),
        traveler_type: item.traveler_type,
        user_id: item.user_id,
        number_of_requests: item.number_of_requests,
        number_of_sessions: item.number_of_sessions,
        requests_per_sessions: item.requests_per_sessions,
        metadata: {
            context: item.context,
            location_count: item.location_count,
            inputs: item.inputs,
        },
    }));

    setActiveUsersData(activeusers);

    const heatmapResultData = heatmapResponse.map((location) => [
      location.lat,
      location.lon,
      location.weight,
    ]);

    setHeatmapData(heatmapResultData)



      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [timeRange]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setSearchParams({ tab: newValue });
  };

  const setTab = (tab) => {
    setSearchParams({ tab });
  };

  const handlePrevMonth = () => {
    console.log('Previous month clicked');
  };

  const handleNextMonth = () => {
    console.log('Next month clicked');
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'overview':
        return <APIRequestsGraphWithCost />;
      case 'detailed':
        return <DetailedTable />;
      case 'heatmap':
        return <Heatmap />;
      default:
        navigate('?tab=overview'); // Redirect to the default tab if an invalid tab is provided
        return null;
    }
  };

  return (
    <>
      <Header toggleTheme={toggleTheme} theme={theme} />
      <DashboardContainer>
        <Sidebar>
            <SidebarTitle>Analytics</SidebarTitle>
            <TabDiv>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              orientation="vertical" // Ensure vertical tabs
            >
            <StyledTab value="overview" label="Overview" />
            <StyledTab value="detailed" label="Detailed" />
            <StyledTab value="active-users" label="Active Users" />
            <StyledTab value="heatmap" label="Heatmap" />
            <StyledTab value="all-users" label="All Users" />
              {/* <StyledTab value="post-agent-setup" label="Post-Booking Agent" /> */}
            </Tabs>
            </TabDiv>
          </Sidebar>

          <ContentArea>
          {activeTab === 'overview' && <APIRequestsGraphWithCost
            chartData={chartData}
            totalCost={totalCost}
            chatCost={chatCost}
            audioCost={audioCost}
            totalChatRequests={totalChatRequests}
            totalAudioRequests={totalAudioRequests}
            costPercentage={costPercentage}
          />}
          {activeTab === 'detailed' && <DetailedTable 
            detailedData={detailedData}
            totalChatRequests={totalChatRequests}
            totalAudioRequests={totalAudioRequests}
          />}
          {activeTab === 'active-users' && <ActiveUsersTable 
            activeUsersData={activeUsersData}
            totalChatRequests={totalChatRequests}
            totalAudioRequests={totalAudioRequests}
          />}
          {activeTab === 'heatmap' && <Heatmap 
            heatmapData={heatmapData}  
          />}
          {activeTab === 'all-users' && <AllUsersTable />}
          {/* {activeTab === 'post-agent-setup' && <PostAgentSetup />} */}
        </ContentArea>

      </DashboardContainer>
    </>
  );
};

export default Dashboard;
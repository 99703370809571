export const lightTheme = {
  body: '#fff',
  text: '#121212',
  background: '#fff',
  primary: '#3b82f6',
  secondary: '#6c63ff',
  tertiary: '#121212',
  get_color: '#2ab673',
  get_hover: '#2ab67380',
  post_color: '#3064e3',
  post_hover: '#3064e380',
  sidebarBg: '#fff',
  sidebarText: '#121212',
  sidebarHover: '#e0e0e0',
  activeBg: '#e0e0e0',
  activeText: '#121212',
  dropdownBg: '#fff',
  dropdownBorder: '#cccccc75',
  inputBg: '#f9f9f9',
  inputBorder: '#ddd',
  secondaryText: '#6e6e80',
  borderColor: '#ececf1',
  tableHeader: '#fafafa',
  fadeEnterDuration: "0.2s",
  fadeEnterTimingFunction: "ease-in",
  fadeExitDuration: "0.15s",
  fadeExitTimingFunction: "ease-out",
};

export const darkTheme = {
  body: '#121212',
  text: '#fff',
  background: '#121212',
  primary: '#3b82f6',
  secondary: '#6c63ff',
  tertiary: '#fff',
  get_color: '#2ab673',
  get_hover: '#2ab67380',
  post_color: '#3064e3',
  post_hover: '#3064e380',
  sidebarBg: '#121212',
  sidebarText: '#fff',
  sidebarHover: '#4d9cff1a',
  activeBg: '#4d9cff1a',
  activeText: '#4d9cff',
  dropdownBg: '#1e1e1e',
  dropdownBorder: '#cccccc75',
  inputBg: '#333',
  inputBorder: '#555',
  secondaryText: '#acacbe',
  borderColor: '#353740',
  tableHeader: '#1a1a1e',
  fadeEnterDuration: "0.2s",
  fadeEnterTimingFunction: "ease-in",
  fadeExitDuration: "0.15s",
  fadeExitTimingFunction: "ease-out",
};

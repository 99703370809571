import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import LoadingSpinner from "../Loading";
import Notification from "../Notification";
import { Button, TextField, Switch, FormControlLabel, Slider, RadioGroup, FormControl, Radio, MenuItem, Select, InputLabel, Chip, Box } from "@mui/material";
import { fadeIn, fadeOut } from "../Animations";

const StyledTextField = styled(TextField)(({ theme }) => ({
    "& .MuiOutlinedInput-root": {
        color: theme.text, // Set the text color
        fontSize: "12px",
        borderRadius: "10px",
        "& fieldset": {
            borderColor: theme.border, // Set the border color
        },
        "&:hover fieldset": {
            borderColor: theme.primary, // Set the hover border color
        },
        "&.Mui-focused fieldset": {
            borderColor: theme.primary, // Set the focus border color
        },
    },
    "& .MuiInputBase-input": {
        color: theme.text, // Set the input text color
    },
}));

const PageContainer = styled.div`
  display: flex;
  position: relative;
  color: ${({ theme }) => theme.text};
  font-size: 12px !important;
  overflow: hidden;
  max-height: 89vh;
  animation: 1s ${fadeIn} ease-out;
`;

const DetailsPanel = styled.div`
  flex: 1;
  overflow: scroll;
  max-height: 85vh;
  display: flex;
  flex-direction: column;
  padding-right: 40px;
`;

const TonePanel = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
  padding-left: 40px;
  flex-direction: column;
  border-radius: 8px;
`;

const SectionTitle = styled.h3`
  margin-top: 5px;
  font-size: 14px;
  cursor: default;
  margin-bottom: 10px;
  font-weight: 600;
  color: ${({ theme }) => theme.secondaryText};
`;

const ButtonGroup = styled.div`
  margin-top: 10px;
  display: flex;
  gap: 10px;
  justify-content: right;
`;

const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.primary} !important;
  color: white;
  margin-right: 10px !important; /* Optional if needed */
  border-radius: 5px !important; /* Match AddButton's border radius */
  font-weight: 600 !important; /* Match AddButton's font weight */
  text-transform: none !important; /* Remove default Material-UI uppercase transformation */

  &:hover {
    background-color: ${({ theme }) => theme.primaryHover || theme.primary} !important;
  }
`;

const InputContainer = styled.div`
  margin: 0px 0px 15px 0px;
`;

const SliderContainer = styled.div`
  color: ${({ theme }) => theme.text};
  border-radius: 8px;
  padding-right: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const IndividualSlider = styled.div`
  color: ${({ theme }) => theme.text};
  padding: 5px;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
`;

const StyledSlider = styled(Slider)`
  & .MuiSlider-thumb {
    color: white;
    border: 2px solid ${({ theme }) => theme.primary};
  }
  & .MuiSlider-track {
    color: ${({ theme }) => theme.primary};
  }
  & .MuiSlider-rail {
    color: ${({ theme }) => theme.border};
  }
  & .MuiSlider-markLabel {
    color: ${({ theme }) => theme.text};
    font-size: 12px;
    
  }
  & .MuiSlider-mark {
    background-color: transparent;
  }
`;


const InputField = styled.textarea`
  width: 100%;
  padding: 8.5px 14px;
  color: ${({ theme }) => theme.text};
  border-color: rgba(0, 0, 0, 0.23);
  border-radius: 10px;
  font-family: Montserrat;
  align-content: center;
  font-size: 14px;
  font-size: 12px;
  background: transparent;
  resize: none; /* Disable manual resizing */
  overflow: hidden; /* Prevent scrollbars */
  min-height: 1.4375em;
  box-sizing: border-box; /* Ensure padding is included in the height calculation */
`;

const List = styled.div`
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  width: 100%; /* Ensure it takes the full width of its container */
`;

const ListItem = styled.div`
  display: flex;
  align-items: center; /* Center all child elements vertically */
  justify-content: space-between;
  gap: 0.5rem;
  line-height: 1.4375em;
  width: 100%;
`;

const DeleteButton = styled.button`
  background: transparent;
  border: none;
  font-size: 16px;
  cursor: pointer;
  height: 40px;
  min-width: 40px; /* Ensure the button has consistent dimensions */
  border-radius: 0px 5px 5px 0px;

  &:hover {
    color: #f00;
  }
`;

const SectionTitleContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const InfoIcon = styled.div`
  margin-left: 8px;
  display: flex;
-webkit-box-align: center;
  cursor: default;
  color: #888;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-top: 5px;
  margin-bottom: 10px;
  svg {
    width: 1em;
    height: 1em;
  }
`;

const Tooltip = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  color: #000;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  width: 250px;
  z-index: 1000;
  display: ${({ visible }) => (visible ? "block" : "none")};

  &:before {
    content: "";
    position: absolute;
    top: -6px;
    left: 15px;
    border-width: 6px;
    border-style: solid;
    border-color: transparent transparent #fff transparent;
  }
`;

const PreAgentSetup = () => {
    const [notification, setNotification] = useState(null);
    const [notificationType, setNotificationType] = useState("success"); // Track notification type (success or error)
    const [name, setName] = useState("");
    const [supportUrl, setSupportUrl] = useState("");
    const [orgName, setOrgName] = useState("");
    const [description, setDescription] = useState("");
    const [longDescription, setLongDescription] = useState("");
    const [error, setError] = useState('');
    const [hotels, setHotels] = useState(false);
    const [attractions, setAttractions] = useState(false);
    const [flights, setFlights] = useState(false);
    const [experiences, setExperiences] = useState(false);
    const [friendly, setFriendly] = useState(1); // 1 is Medium by default
    const [empathy, setEmpathy] = useState(1); // 1 is Medium by default
    const [factual, setFactual] = useState(1); // 1 is Medium by default
    const [energetic, setEnergetic] = useState(1); // 1 is Medium by default
    const [length, setLength] = useState(1); // 1 is Medium by default
    const [depth, setDepth] = useState(1); // 1 is Medium by default
    const [enthusiasm, setEnthusiasm] = useState(1); // 1 is Medium by default
    const [detail, setDetail] = useState(1); // 1 is Medium by default
    const [ticketScope, setTicketScope] = useState("global"); // 'global' or 'specific'
    const [newLocation, setNewLocation] = useState(''); // Tracks input in the TextField
    const [locations, setLocations] = useState([]);
    const [guardrails, setGuardrails] = useState([""]);
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const textAreaRefs = useRef([]);

    const handleMouseEnter = () => setTooltipVisible(true);
    const handleMouseLeave = () => setTooltipVisible(false);

    useEffect(() => {
        const fetchPreBookConfig = async () => {
            setLoading(true);
            const token = localStorage.getItem('authToken');
            if (!token) {
                console.error('No token found');
                return;
            }

            try {
                const response = await fetch('https://platform.iwander.io/agent_setup/get-pre-booking-config', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch API keys');
                }

                const data = await response.json();
                setOrgName(data.pre_booking_agent.organisation_name);
                setDescription(data.pre_booking_agent.organisation_description);
                setLongDescription(data.pre_booking_agent.long_description);
                setName(data.pre_booking_agent.guide_name);
                setSupportUrl(data.pre_booking_agent.support_url);
                setTicketScope(data.pre_booking_agent.ticket_scope)
                setLocations(data.pre_booking_agent.locations)
                setGuardrails(data.pre_booking_agent.guardrails) 
                setFriendly(data.pre_booking_agent.tone_of_voice.friendly)
                setEmpathy(data.pre_booking_agent.tone_of_voice.empathy)
                setFactual(data.pre_booking_agent.tone_of_voice.factual)
                setEnergetic(data.pre_booking_agent.tone_of_voice.energetic)
                setLength(data.pre_booking_agent.tone_of_voice.length)
                setDepth(data.pre_booking_agent.tone_of_voice.depth)
                setEnthusiasm(data.pre_booking_agent.tone_of_voice.enthusiasm)
                setDetail(data.pre_booking_agent.tone_of_voice.detail)
                const purposes = data.pre_booking_agent.purpose || [];
                setAttractions(purposes.includes('attractions'));
                setHotels(purposes.includes('hotels'));
                setFlights(purposes.includes('flights'));
                setExperiences(purposes.includes('experiences'));
            } catch (error) {
                console.error('Error fetching API keys:', error);
            } finally {
                setLoading(false); // Stop loading
            }

        };

        fetchPreBookConfig();
    }, []);

    const removeNotification = () => {
        setNotification(null); // Remove the notification
    };


    const professionalMarks = [
        { value: 0, label: "Professional" },
        { value: 1, label: "Neutral" },
        { value: 2, label: "Friendly" },
    ];
    const empathyMarks = [
        { value: 0, label: "Empathetic" },
        { value: 1, label: "Neutral" },
        { value: 2, label: "Direct" },
    ];
    const factMarks = [
        { value: 0, label: "Factual" },
        { value: 1, label: "Neutral" },
        { value: 2, label: "Inspirational" },
    ];
    const energyMarks = [
        { value: 0, label: "Calm" },
        { value: 1, label: "Neutral" },
        { value: 2, label: "Energetic" },
    ];

    const lengthMarks = [
        { value: 0, label: "Short" },
        { value: 1, label: "Medium" },
        { value: 2, label: "Long" },
    ];
    const depthMarks = [
        { value: 0, label: "Minimal" },
        { value: 1, label: "Balanced" },
        { value: 2, label: "Comprehensive" },
    ];
    const enthusiasMarks = [
        { value: 0, label: "Low" },
        { value: 1, label: "Moderate" },
        { value: 2, label: "High" },
    ];
    const detailMarks = [
        { value: 0, label: "High-Level" },
        { value: 1, label: "Moderate" },
        { value: 2, label: "In-Depth" },
    ];

    const availableLocations = [
        'New York', 'London', 'Paris', 'Tokyo', 'Sydney', 'Los Angeles', 'Berlin', 'Rome'
    ];

    const handleScopeChange = (e) => {
        setTicketScope(e.target.value);
        setLocations([]); // Clear locations when scope changes
    };


    // Add location on Enter key press
    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && newLocation.trim() !== '') {
            // Add location if not already in the list
            if (!locations.includes(newLocation.trim())) {
                setLocations([...locations, newLocation.trim()]);
            }
            setNewLocation(''); // Clear the input field
        }
    };

    // Remove a location
    const handleDelete = (locationToDelete) => {
        setLocations(locations.filter((loc) => loc !== locationToDelete));
    };

    const handleChange = (e) => {
        const value = e.target.value;
        if (value.length <= 100) {
            setDescription(value);
            console.log(description)
            setError(''); // Clear error if input is valid
        } else {
            setError('Description must be 100 characters or less');
        }
    };

    const handleInput = (index) => {
        const element = textAreaRefs.current[index];
        if (element) {
            element.style.height = "auto"; // Reset height to min-height
            element.style.height = `${element.scrollHeight}px`; // Adjust to content
        }
    };

    const handleInputChange = (value, index) => {
        const updatedGuardrails = [...guardrails];
        updatedGuardrails[index] = value;

        // Remove the last empty input if the current input is cleared
        if (value.trim() === "" && index === guardrails.length - 2 && guardrails[guardrails.length - 1] === "") {
            updatedGuardrails.pop();
        }

        // Add a new empty input if this is the last input and it's being filled
        if (value.trim() && index === guardrails.length - 1) {
            updatedGuardrails.push("");
        }

        setGuardrails(updatedGuardrails);
    };

    const handleDeleteGuardrail = (index) => {
        const updatedGuardrails = guardrails.filter((_, i) => i !== index);
        setGuardrails(updatedGuardrails);
    };

    useEffect(() => {
        // Adjust height for each textarea after rendering
        guardrails.forEach((_, index) => {
            handleInput(index);
        });
    }, [guardrails]);



    const handleSaveChanges = async () => {
        // Construct the payload
        const payload = {
            "pre_booking":
            {
                organisation_name: orgName,
                organisation_description: description,
                long_description: longDescription,
                guide_name: name,
                support_url: supportUrl,
                purpose: [],
                ticket_scope: ticketScope,
                locations: locations,
                guardrails: guardrails,
                tone_of_voice: {
                    friendly,
                    empathy,
                    factual,
                    energetic,
                    length,
                    depth,
                    enthusiasm,
                    detail,
                },
            }
        };

        // Add selected purpose items based on the switches
        if (attractions) payload.pre_booking.purpose.push("attractions");
        if (hotels) payload.pre_booking.purpose.push("hotels");
        if (flights) payload.pre_booking.purpose.push("flights");
        if (experiences) payload.pre_booking.purpose.push("experiences");

        try {
            // Send the payload to the API
            const token = localStorage.getItem("authToken");
            const response = await fetch("https://platform.iwander.io/agent_setup/update-pre-booking-config", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(payload),
            });

        if (response.ok) {
            setNotification("🚀 Changes saved successfully!");
            setNotificationType("success"); // Set notification type to success
        } else {
            setNotification("⚠️ Failed to save changes. Please try again.");
            setNotificationType("error"); // Set notification type to error
        }

        // alert("Configuration updated successfully!");
    } catch (error) {
        console.error("Error updating configuration:", error);
        alert("Failed to update configuration. Please try again.");
    }
};

return (
    <PageContainer>
        {notification && (
            <Notification
                message={notification}
                type={notificationType} // Pass the notification type
                onRemove={removeNotification}
            />
        )}

        <LoadingSpinner loading={loading} />
        <DetailsPanel>
            <InputContainer>
                <SectionTitleContainer onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                    <SectionTitle>Guardrails</SectionTitle>
                    <InfoIcon>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                        >
                            <path d="M13 12a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0v-4Zm-1-2.5A1.25 1.25 0 1 0 12 7a1.25 1.25 0 0 0 0 2.5Z"></path>
                            <path
                                fillRule="evenodd"
                                d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2ZM4 12a8 8 0 1 1 16 0 8 8 0 0 1-16 0Z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                    </InfoIcon>
                    <Tooltip visible={tooltipVisible}>
                        Guardrails are rules or constraints that ensure the chatbot provides safe and relevant responses to user inquiries.
                        They also allow you to apply key company policies that helps the agent align with important messaging.
                    </Tooltip>
                </SectionTitleContainer>

                <List>
                    {guardrails.map((guardrail, index) => (
                        <ListItem key={index}>
                            <InputField
                                ref={(el) => (textAreaRefs.current[index] = el)}
                                value={guardrail}
                                onChange={(e) => handleInputChange(e.target.value, index)}
                                onInput={handleInput}
                                placeholder="Enter a guardrail..."
                            />
                            {guardrail.trim() && (
                                <DeleteButton onClick={() => handleDeleteGuardrail(index)}>✖</DeleteButton>
                            )}
                        </ListItem>
                    ))}
                </List>
            </InputContainer>

        </DetailsPanel>
        <TonePanel>

            <SectionTitle>Tone of Voice</SectionTitle>

            <SliderContainer>
                {/* Temperature Slider */}
                <IndividualSlider>
                    Professional vs. Friendly

                    <StyledSlider
                        value={friendly}
                        onChange={(e, newValue) => setFriendly(newValue)}
                        step={1}
                        marks={professionalMarks}
                        min={0}
                        max={2}
                    />
                </IndividualSlider>


                {/* Top P Slider */}
                <IndividualSlider>
                    Empathetic vs. Neutral

                    <StyledSlider
                        value={empathy}
                        onChange={(e, newValue) => setEmpathy(newValue)}
                        step={1}
                        marks={empathyMarks}
                        min={0}
                        max={2}
                    />
                </IndividualSlider>
                <IndividualSlider>
                    Factual vs. Inspirational

                    <StyledSlider
                        value={factual}
                        onChange={(e, newValue) => setFactual(newValue)}
                        step={1}
                        marks={factMarks}
                        min={0}
                        max={2}
                    />
                </IndividualSlider>
                <IndividualSlider>
                    Calm vs. Energetic

                    <StyledSlider
                        value={energetic}
                        onChange={(e, newValue) => setEnergetic(newValue)}
                        step={1}
                        marks={energyMarks}
                        min={0}
                        max={2}
                    />
                </IndividualSlider>
                <IndividualSlider>
                    Answer Length

                    <StyledSlider
                        value={length}
                        onChange={(e, newValue) => setLength(newValue)}
                        step={1}
                        marks={lengthMarks}
                        min={0}
                        max={2}
                    />
                </IndividualSlider>
                <IndividualSlider>
                    Depth of Recommendations

                    <StyledSlider
                        value={depth}
                        onChange={(e, newValue) => setDepth(newValue)}
                        step={1}
                        marks={depthMarks}
                        min={0}
                        max={2}
                    />
                </IndividualSlider>
                <IndividualSlider>
                    Level of Enthusiasm

                    <StyledSlider
                        value={enthusiasm}
                        onChange={(e, newValue) => setEnthusiasm(newValue)}
                        step={1}
                        marks={enthusiasMarks}
                        min={0}
                        max={2}
                    />
                </IndividualSlider>
                <IndividualSlider>
                    Detail Depth

                    <StyledSlider
                        value={detail}
                        onChange={(e, newValue) => setDetail(newValue)}
                        step={1}
                        marks={detailMarks}
                        min={0}
                        max={2}
                    />
                </IndividualSlider>

            </SliderContainer>

            <ButtonGroup>
                {/* <StyledButton variant="outlined">Reset</StyledButton> */}
                <StyledButton variant="contained" onClick={handleSaveChanges}>Save Changes</StyledButton>
            </ButtonGroup>

        </TonePanel>
    </PageContainer>
);
}
export default PreAgentSetup;
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    font-family: 'Montserrat';
    margin: 0;
    padding: 0;
    transition: background-color 0.3s ease, color 0.3s ease;
  },
  :root {
    --body-bg: ${({ theme }) => theme.body};
    --text-color: ${({ theme }) => theme.text};
    --primary-color: ${({ theme }) => theme.primary};
    --secondary-color: ${({ theme }) => theme.secondary};
    --tertiary-color: ${({ theme }) => theme.tertiary};
    --post-color: ${({ theme }) => theme.post_color};
    --sidebar-bg: ${({ theme }) => theme.sidebarBg};
    --dropdown-bg: ${({ theme }) => theme.dropdownBg};
    --dropdown-border: ${({ theme }) => theme.dropdownBorder};
    --input-bg: ${({ theme }) => theme.inputBg};
    --input-border: ${({ theme }) => theme.inputBorder};
    --secondary-text-color: ${({ theme }) => theme.secondaryText};
    --table-header: ${({ theme }) => theme.tableHeader};
    --background:  ${({ theme }) => theme.background};
  }
`;

export default GlobalStyle;

import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ProductTable from './ProductTable';
import PartnerAPI from './PartnerAPI';
import PreAgentSetup from './PreAgentSetup';
import OrgSetup from './OrganisationSetup';
import PostAgentSetup from './PostAgentSetup';
import DeployChatbotPage from './Deployment';
import PreChatbotCustomizer from './PreChatbotCustomizer';
import PostChatbotCustomizer from './PostChatbotCustomizer';
import Header from '../Header';
import { Tabs, Tab, Box } from '@mui/material';


const DashboardContainer = styled.div`
  display: flex;
  height: 90vh;
  overflow: hidden;
  margin-top: 54px;
  margin-left: 8px;
  margin-right: 8px;
  color: ${({ theme }) => theme.text};
`;

const Sidebar = styled.div`
  display: flex;
  flex-direction: column; /* Stack tabs vertically */
  width: 200px; /* Fixed width for the sidebar */
  padding: 14px 0;
  font-size: 14px;
  z-index: 101;
`;

const ContentArea = styled.div`
  flex: 1;
  padding: 20px 20px 0px 20px;
  background: ${({ theme }) => theme.inputBg};
  border-radius: 10px;
  overflow: scroll;
  border: 1px solid;
  border-color: ${({ theme }) => theme.borderColor};
`;

const SidebarTitle = styled.div`
  padding-right: 16px;
  padding-left: 16px;
  margin: 15px 0px;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
`;

const TabDiv = styled.div`
  padding-top: 12px;
  padding-bottom: 16px;
  overflow: auto;
`;

const StyledTab = styled(Tab)`
  color: ${({ theme }) => theme.secondaryText} !important;
  font-size: 14px;
  text-transform: none !important;
  font-family: Montserrat;
  align-items: flex-start !important; /* Align text to the left */
  justify-content: flex-start !important; /* Align text to the left */

  &:hover {
    color: ${({ theme }) => theme.text} !important;
  }

  &.MuiTab-root {
    font-family: Montserrat;
    font-weight: 500;
  }

  &.Mui-selected {
    color: ${({ theme }) => theme.primary} !important;
    background-color: ${({ theme }) => theme.inputBg} !important;
    font-weight: bold !important;
    font-family: Montserrat;
  }
`;

function Configuration({ toggleTheme, theme }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentTab = searchParams.get('tab') || 'products';
  const [activeTab, setActiveTab] = useState(currentTab);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setSearchParams({ tab: newValue });
  };

  return (
    <>
      <Header toggleTheme={toggleTheme} theme={theme} />
      <DashboardContainer>
        {/* Sidebar */}
        <Sidebar>
          <SidebarTitle>CONFIGURATION</SidebarTitle>
          <TabDiv>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              orientation="vertical" // Ensure vertical tabs
            >
              <StyledTab value="products" label="Products" />
              <StyledTab value="iwander-affiliates" label="iWander Affiliates" />
              <StyledTab value="org-setup" label="Organisation Details" />
              <StyledTab value="agent-setup" label="Agent Setup" />
              <StyledTab value="customize-pre-chatbot" label="Pre-Booking" />
              <StyledTab value="customize-post-chatbot" label="Post-Booking" />
              <StyledTab value="deployment" label="Deployment" />
              {/* <StyledTab value="post-agent-setup" label="Post-Booking Agent" /> */}
            </Tabs>
          </TabDiv>
        </Sidebar>

        {/* Content Area */}
        <ContentArea>
          {activeTab === 'products' && <ProductTable />}
          {activeTab === 'iwander-affiliates' && <PartnerAPI />}
          {activeTab === 'org-setup' && <OrgSetup />}
          {activeTab === 'agent-setup' && <PreAgentSetup />}
          {activeTab === 'customize-pre-chatbot' && <PreChatbotCustomizer />}
          {activeTab === 'customize-post-chatbot' && <PostChatbotCustomizer />}
          {activeTab === 'deployment' && <DeployChatbotPage />}
          {/* {activeTab === 'post-agent-setup' && <PostAgentSetup />} */}
        </ContentArea>
      </DashboardContainer>
    </>
  );
}

export default Configuration;
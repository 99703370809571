
import React, { useState, useRef, useEffect, useMemo } from "react";

const PostChatbotPage = () => {
  const [messages, setMessages] = useState([
  ]);
  const [input, setInput] = useState(""); // User input
  const [sessionId, setSessionId] = useState(sessionStorage.getItem("session_id") || ""); // Stored session ID
  const guideName = "wanda"; // Static guide name
  const chatWsRef = useRef(null); // WebSocket reference
  const messageIdRef = useRef(null); // Current message ID reference
  const textStreamRef = useRef(""); // For streaming text storage
  const chatBoxRef = useRef(null); // Reference to the chatBox
  const [customization, setCustomization] = useState({});
  const [nextStories, setNextStories] = useState([]);
  const [initialPrompts, setInitialPrompts] = useState([]);
  const [hidePrompts, setHidePrompts] = useState(false);
  const [showNextStories, setShowNextStories] = useState(false);
  const [tickets, setTickets] = useState([]);
  const urlParams = new URLSearchParams(window.location.search);
  const apiKeyRef = useRef(urlParams.get("id"));


  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages, tickets, nextStories]);

  useEffect(() => {
    const fetchChatbotStyles = async () => {
      try {
        const response = await fetch(`https://platform.iwander.io/agent_setup/post-chatbot-config?api_key=${apiKeyRef.current}`, {
          method: "GET",
        });

        if (response.ok) {
          const styles = await response.json();
          console.log(styles)
          setCustomization({
            chatBgColor: styles.chatBgColor || "#0d1b4e",
            widgetBgColor: styles.widgetBgColor || "#0d1b4e",
            chatTextColor: styles.chatTextColor || "#FFFFFF",
            widgetTextColor: styles.widgetTextColor || "#000000",
            promptBgColor: styles.promptBgColor || "#FFFFFF",
            promptTextColor: styles.promptTextColor || "#0d1b4e",
            ticketBgColor: styles.ticketBgColor || "#0d1b4e",
            ticketTextColor: styles.ticketTextColor || "#FFFFFF",
            bubbleText: styles.bubble_text || "Hi, I'm your travel assistant!",
            introText: styles.intro_text || "How can I help you today?",
            userPrompts: styles.user_prompts || ["Find a Hotel", "Destination Ideas", "Deals & Offers", "Help with My Booking"],
            headerImageUrl: styles.header_image_url,
            iconUrl: styles.icon_url,
            guide_name: styles.guide_name,
          });
          console.log("Icon URL from API:", styles.icon_url);

          document.body.style.backgroundColor = "#fff";

          setMessages((prev) => [
            ...prev.filter((msg) => msg.id !== "welcome"),
            { id: "welcome", sender: "bot", text: styles.intro_text },
          ]);

          setInitialPrompts(styles.user_prompts || ["Find a Hotel", "Destination Ideas", "Deals & Offers", "Help with My Booking"]);
        } else {
          console.error("Failed to fetch chatbot styles.");
        }
      } catch (error) {
        console.error("Error fetching chatbot styles:", error);
      }
    };

    fetchChatbotStyles();
  }, []);


  // Function to initialize or reconnect WebSocket
  const initializeWebSocket = (messageData) => {
    chatWsRef.current = new WebSocket(
      `wss://platform.iwander.io/chat/ws/get-script/api-key/${apiKeyRef.current}/ws`
    );

    chatWsRef.current.onopen = () => {
      console.log("WebSocket connected.");
      // Send the new message data after connection is established
      chatWsRef.current.send(JSON.stringify(messageData));
    };

    chatWsRef.current.onmessage = (event) => {
      const data = JSON.parse(event.data);

      if (data.type === "session") {
        sessionStorage.setItem("session_id", data.data);
        setSessionId(data.data);
      }

      if (data.type === "message_id") {
        messageIdRef.current = data.data;
        setMessages((prev) => [
          ...prev,
          { id: data.data, sender: "bot", text: "", tickets: [] },
        ]);
      }

      if (data.type === "text") {
        appendChatMessage(data.data);
      }

      if (data.type === "tickets") {
        setMessages((prev) =>
          prev.map((msg) =>
            msg.id === messageIdRef.current
              ? { ...msg, tickets: data.data } // Attach tickets to the message
              : msg
          )
        );
      }

      if (data.type === "next_stories") {
        const nextStoriesArray = data.data?.next_stories?.[0]; // Access the first array inside next_stories
        if (Array.isArray(nextStoriesArray)) {
          const extractedTitles = nextStoriesArray.slice(0, 4).map((story) => story.title);
          setNextStories(extractedTitles);
          setShowNextStories(true);
        } else {
          console.error("Invalid next_stories format:", data.data);
          setNextStories([]); // Fallback to empty if data is malformed
        }
      }
    };

    chatWsRef.current.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    chatWsRef.current.onclose = () => {
      console.log("WebSocket closed.");
    };
  };

  // Append new chat message
  const appendChatMessage = (text) => {
    textStreamRef.current += text;

    setMessages((prev) =>
      prev.map((msg) =>
        msg.id === messageIdRef.current
          ? {
            ...msg,
            text: textStreamRef.current
              .split("\n") // Split the text by newlines
              .filter((line) => line.trim()) // Remove empty lines
              .join("\n"), // Rejoin cleaned lines for further updates
          }
          : msg
      )
    );
  };

  const handleButtonClick = (title) => {
    setInput(title); // Set the input to the button title
    sendMessage(title); // Pass the title as a message
  };

  // Function to send user message
  const sendMessage = (messageText = input) => {
    if (messageText.trim()) {
      setHidePrompts(true);
      setShowNextStories(false);
      const messageData = {
        session_id: sessionId || undefined,
        message: messageText,
        guide_name: guideName,
        pre_post: "post_booking_agent",
      };

      setMessages((prev) => [
        ...prev,
        { id: Date.now(), sender: "user", text: messageText },
      ]);

      textStreamRef.current = ""; // Reset text stream

      // Check if WebSocket is open, reconnect if closed
      if (!chatWsRef.current || chatWsRef.current.readyState === WebSocket.CLOSED) {
        console.log("Reconnecting WebSocket...");
        initializeWebSocket(messageData);
      } else {
        // Send message if WebSocket is open
        chatWsRef.current.send(JSON.stringify(messageData));
      }

      setInput(""); // Clear input field
    }
  };
  const styles = useMemo(() => ({
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100vh", // Full viewport height
      overflow: "hidden", // Prevent container from being scrollable
    },
    chatBox: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      overflowY: "auto",
      padding: "10px",
      borderRadius: "8px",
      backgroundColor: "#fff", // Optional background color
    },
    chatMessageWrapper: {
      display: "flex",
      flexDirection: "column",
    },
    botOverallWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      margin: "10px 0px",
    },
    botMessageWrapper: {
      display: "flex",
      alignItems: "flex-start",
      margin: "10px 0px",
    },
    botOutputWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      width: "-webkit-fill-available",
      margin: "10px 0",
    },
    userMessageWrapper: {
      display: "flex",
      justifyContent: "flex-end",
      margin: "10px 0",
    },
    botAvatar: {
      width: "32px",
      height: "32px",
      borderRadius: "50%",
      marginRight: "10px",
    },
    botMessage: {
      background: "#F1F4F8",
      color: "#333",
      borderRadius: "10px",
      padding: "10px 15px 0px 15px",
      maxWidth: window.innerWidth <= 768 ? "85%" : "75%",
      boxShadow: "0 1px 4px rgba(0, 0, 0, 0.1)",
      position: "relative",
    },
    userMessage: {
      background: customization.chatBgColor || "#007BFF",
      color: customization.chatTextColor || "#fff",
      borderRadius: "10px",
      padding: "10px 15px 0px 15px",
      maxWidth: window.innerWidth <= 768 ? "85%" : "75%",
      alignSelf: "flex-end",
      boxShadow: "0 1px 4px rgba(0, 0, 0, 0.1)",
      position: "relative",
    },
    messageContent: {
    },
    botTimestamp: {
      fontSize: "12px",
      color: "#666",
      marginTop: "5px",
      textAlign: "left", // Align timestamp to the right within the bubble
    },
    userTimestamp: {
      fontSize: "12px",
      color: "#666",
      marginTop: "5px",
      marginRight: "5px",
      textAlign: "right",
      alignSelf: "flex-end",
    },
    inputBox: {
      display: "flex",
      padding: "10px",
      fontSize: "16px",
      background: "#fff",
      position: "sticky",
      bottom: 0,
      zIndex: 1000,
    },
    input: {
      flex: 1,
      padding: "10px",
      borderRadius: "20px",
      border: "2px solid",
      fontSize: "16px",
      borderColor: customization.chatBgColor,
      marginRight: "5px",
    },
    buttonContainer: {
      display: "flex",
      backgroundColor: "rgb(255, 255, 255)",
      justifyContent: "flex-start",
      gap: "10px",
      margin: "10px 0px 10px 10px",
      paddingRight: "10px",
      overflowX: "auto",
    },
    meta: {
      fontSize: "12px",
      color: "#666",
      marginBottom: "3px",
    },
    promptWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end", // Align to the right
      gap: "10px", // Space between buttons
      marginTop: "10px",
      marginBottom: "10px",
      paddingRight: "10px",
    },
    promptButton: {
      backgroundColor: customization.promptBgColor,
      border: "2px solid",
      borderColor: customization.promptTextColor,
      color: customization.promptTextColor,
      borderRadius: "20px",
      padding: "8px 12px",
      fontSize: "14px",
      cursor: "pointer",
      outline: "none",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      textAlign: "left",
      transition: "all 0.2s ease-in-out",
      whiteSpace: "pre-line", // Prevent text from wrapping
    },
    nextRecsButton: {
      backgroundColor: customization.promptBgColor,
      border: "2px solid",
      borderColor: customization.promptTextColor,
      color: customization.promptTextColor,
      borderRadius: "20px",
      padding: "8px 12px",
      fontSize: "14px",
      cursor: "pointer",
      outline: "none",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      textAlign: "center",
      transition: "all 0.2s ease-in-out",
      whiteSpace: "nowrap", // Prevent text from wrapping
    },
    ticketsContainer: {
      display: "flex", // Flexbox layout
      gap: "15px", // Space between cards
      width: "100%",
      overflowX: "auto", // Enable horizontal scrolling
      whiteSpace: "nowrap", // Prevent cards from wrapping to the next line
      margin: "20px 0px 0px 0px",
      paddingRight: "10px",
    },
    ticketCard: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      minWidth: "300px", // Set a minimum width for each card
      maxWidth: "300px", // Fix card width
      border: "1px solid #ddd",
      borderRadius: "16px",
      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
      backgroundColor: "#fff",
      overflow: "hidden",
    },
    ticketImage: {
      objectFit: "cover",
      height: "153px",
      width: '100%',
      borderRadius: "16px 16px 0px 0px",
    },
    ticketContent: {
      padding: "15px",
      display: "flex",
      flexDirection: "column",
      flex: 1,
    },
    ticketTitle: {
      fontSize: "14px",
      fontWeight: "600",
      lineHeight: "19px",
      margin: "0px",
      color: "#333",
      paddingBottom: "4px",
      paddingTop: "4px",
      flex: 1, // Pushes the button to the bottom
      minHeight: "20px", // Minimum height for the description
      maxHeight: "35px", // Maximum height for the description
      overflow: "hidden", // Hide overflowing text
      textOverflow: "ellipsis", // Add ellipsis for overflow
      display: "-webkit-box", // Enables line clamping
      WebkitLineClamp: 3, // Limit the number of visible lines
      WebkitBoxOrient: "vertical", // Set the orientation to vertical
      whiteSpace: "normal", // Allow text to wrap onto the next line
      wordBreak: "break-word", // Break words at boundaries if needed

    },
    ticketDescription: {
      fontSize: "12px",
      color: "#555",
      lineHeight: "20px",
      marginTop: "0px",
      marginBottom: "20px",
      paddingTop: "4px",
      flex: 1, // Pushes the button to the bottom
      minHeight: "40px", // Minimum height for the description
      maxHeight: "60px", // Maximum height for the description
      overflow: "hidden", // Hide overflowing text
      textOverflow: "ellipsis", // Add ellipsis for overflow
      display: "-webkit-box", // Enables line clamping
      WebkitLineClamp: 3, // Limit the number of visible lines
      WebkitBoxOrient: "vertical", // Set the orientation to vertical
      whiteSpace: "normal", // Allow text to wrap onto the next line
      wordBreak: "break-word", // Break words at boundaries if needed
    },
    bookNowButton: {
      textAlign: "center",
      backgroundColor: customization.ticketBgColor,
      color: customization.ticketTextColor,
      textDecoration: "none",
      borderRadius: "25px",
      padding: "10px 0",
      fontWeight: "bold",
      transition: "background 0.3s",
      cursor: "pointer",
    },
  }), [customization]);

  return (
    <div style={styles.container}>
      <div style={styles.chatBox} ref={chatBoxRef}>
        <div style={styles.chatMessageWrapper}>
          {messages.map((msg, index) => (
            <div
              key={index}
              style={msg.sender === "user" ? styles.userMessageWrapper : styles.botOverallWrapper}
            >
              <div style={styles.botMessageWrapper}>
              {msg.sender === "bot" && (
                <img
                  src={customization.iconUrl}
                  alt="Bot Avatar"
                  style={styles.botAvatar}
                />
              )}
              <div style={styles.botOutputWrapper}>
                <div style={msg.sender === "user" ? styles.userMessage : styles.botMessage}>
                  <div style={styles.messageContent}>
                    {msg.text.split("\n").map((line, i) => (
                      <div key={i} style={{ marginBottom: "10px" }}>
                        {line}
                      </div>
                    ))}
                  </div>
                </div>
                <div style={msg.sender === "user" ? styles.userTimestamp : styles.botTimestamp}>
                  {msg.sender === "bot" ? customization.guide_name : "You"} ·{" "}
                  {new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}
                </div>
              </div>
              </div>
              {/* Render tickets if they exist */}
              {msg.tickets && msg.tickets.length > 0 && (
                <div style={styles.ticketsContainer}>
                  {msg.tickets.map((ticket, ticketIndex) => (
                    <div key={ticketIndex} style={styles.ticketCard}>
                      <img
                        src={ticket.image_url}
                        alt={ticket.title}
                        style={styles.ticketImage}
                      />
                      <div style={styles.ticketContent}>
                        <h3 style={styles.ticketTitle}>{ticket.title}</h3>
                        <p style={styles.ticketDescription}>{ticket.description}</p>
                        <a
                          href={ticket.productUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={styles.bookNowButton}
                        >
                          Book Now
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
          {!hidePrompts && (
            <div style={styles.promptWrapper}>
              {initialPrompts.map((title, index) => (
                <button
                  key={index}
                  style={styles.promptButton}
                  onClick={() => handleButtonClick(title)} // Send title as a message
                >
                  {title}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
      {showNextStories && (
        <div style={styles.buttonContainer}>
          {nextStories.map((title, index) => (
            <button
              key={index}
              style={styles.nextRecsButton}
              onClick={() => handleButtonClick(title)} // Send title when clicked
            >
              {title}
            </button>
          ))}
        </div>
      )}
      <div style={styles.inputBox}>
        <input
          style={styles.input}
          type="text"
          value={input}
          placeholder="Type your message..."
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && sendMessage()}
        />
      </div>
    </div>
  );
};

export default PostChatbotPage;
import React from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
import styled from "styled-components";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
`;

const MetricsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;

const MetricBox = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.background};
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: left;
  padding-left: 30px
`;

const MetricTitle = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.textSecondary};
  margin-bottom: 5px;
`;

const MetricValue = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
`;

const MainContent = styled.div`
  display: flex;
  gap: 20px;
`;

const GraphContainer = styled.div`
  flex: 3;
  background-color: ${({ theme }) => theme.background};
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
`;

const ProductClicksContainer = styled.div`
  flex: 2;
  background-color: ${({ theme }) => theme.background};
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
`;

const SectionTitle = styled.h2`
  font-size: 14px;
  margin-bottom: 15px;
`;

const ProductClickTable = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ProductRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};

  &:last-child {
    border-bottom: none;
  }
`;

const ProductName = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.text};
`;

const ProductClicks = styled.p`
  font-size: 14px;
  font-weight: bold;
`;

const OverviewTab = ({
  chartData,
  totalChatRequests,
  totalSessions,
  totalClicks,
  clicksPerSession,
  productClicks,
}) => {
  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
      },
    },
  };

  return (
    <Container>
      {/* Metrics Section */}
      <MetricsContainer>
        <MetricBox>
          <MetricTitle>Total Chat Requests</MetricTitle>
          <MetricValue>{totalChatRequests}</MetricValue>
        </MetricBox>
        <MetricBox>
          <MetricTitle>Total Sessions</MetricTitle>
          <MetricValue>{totalSessions}</MetricValue>
        </MetricBox>
        <MetricBox>
          <MetricTitle>Total Product Clicks</MetricTitle>
          <MetricValue>{totalClicks}</MetricValue>
        </MetricBox>
        <MetricBox>
          <MetricTitle>Product Clicks Per Session</MetricTitle>
          <MetricValue>{clicksPerSession}</MetricValue>
        </MetricBox>
      </MetricsContainer>

      {/* Main Content Section */}
      <MainContent>
        {/* Chart Section */}
        <GraphContainer>
          <SectionTitle>Requests Per Day</SectionTitle>
          {chartData ? <Bar data={chartData} options={chartOptions} /> : <p>Loading...</p>}
        </GraphContainer>

        {/* Product Clicks Section */}
        <ProductClicksContainer>
          <SectionTitle>Product Clicks</SectionTitle>
          <ProductClickTable>
            {Object.entries(productClicks).map(([product, clicks]) => (
              <ProductRow key={product}>
                <ProductName>{product}</ProductName>
                <ProductClicks>{clicks}</ProductClicks>
              </ProductRow>
            ))}
          </ProductClickTable>
        </ProductClicksContainer>
      </MainContent>
    </Container>
  );
};

export default OverviewTab;
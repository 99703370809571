import React, { useState } from 'react';
import styled from 'styled-components';
import { TextField, Switch, FormControlLabel, Slider, RadioGroup, FormControl, Radio, MenuItem, InputLabel, Chip, Box } from "@mui/material";
import { useNavigate } from 'react-router-dom';

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: ${({ theme }) => theme.body};
`;

const OnboardingBox = styled.div`
  max-width: 600px;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.cardBackground};
`;

const ProgressBar = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  margin-top: 5px;
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 600;
  color: ${({ theme }) => theme.secondaryText};
`;

const ProgressLine = styled.div`
  flex: 1;
  height: 4px;
  background-color: ${({ active, theme }) => (active ? theme.primary : theme.border)};
  border-radius: 2px;
  margin: 0 5px;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.text};
`;

const Subtitle = styled.p`
  font-size: 1rem;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.secondaryText};
`;

const Section = styled.div`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  font-size: 1rem;
  border: 1px solid ${({ theme }) => theme.inputBorder};
  border-radius: 5px;
  color: ${({ theme }) => theme.text};
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  font-size: 1rem;
  border: 1px solid ${({ theme }) => theme.inputBorder};
  border-radius: 5px;
  color: ${({ theme }) => theme.text};
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
`;

const Option = styled.div`
  flex: 1 1 calc(50% - 10px);
  padding: 15px;
  text-align: center;
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 10px;
  background-color: ${({ selected, theme }) => (selected ? theme.primary : theme.cardBackground)};
  color: ${({ selected, theme }) => (selected ? '#fff' : theme.text)};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.primary};
    color: #fff;
  }
`;

const NavigationButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const Button = styled.button`
  background-color: ${({ theme }) => theme.primary};
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;

  &:disabled {
    background-color: ${({ theme }) => theme.disabled};
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background-color: ${({ theme }) => theme.primaryHover};
  }
`;

const SectionTitle = styled.h3`
  margin-top: 5px;
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 600;
  color: ${({ theme }) => theme.secondaryText};
`;

const InputContainer = styled.div`
  margin: 5px 0px 15px 0px;
`;

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    color: "#121212",
    fontSize: "12px",
    borderRadius: "10px",
    "& fieldset": {
      borderColor: theme.border, // Set the border color
    },
    "&:hover fieldset": {
      borderColor: "#3b82f6", // Set the hover border color
    },
    "&.Mui-focused fieldset": {
      borderColor: "#3b82f6", // Set the focus border color
    },
  },
  "& .MuiInputBase-input": {
    color: "#121212", // Set the input text color
  },
}));


const StyledSelect = styled.select`
  width: 100%;
  padding: 12px;
  font-size: 12px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.border};
  color: #121212;
  background-color: transparent;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%233b82f6' d='M2 5L0 2h4z'/%3E%3C/svg%3E"); 
  background-repeat: no-repeat;
  background-position: right 15px top 10px;
  background-size: 10px;

  &:hover {
    border-color: #3b82f6;
  }

  &:focus {
    outline: none;
    border-color: #3b82f6;
  }

  & option {
    color: #121212;
  }
`


// Main Onboarding Component
const OnboardingPage = () => {
  const [step, setStep] = useState(1);
  const [selectedOption, setSelectedOption] = useState('');
  const [value, setValue] = useState("");
  const [firstName, setFirstName] = useState(() => localStorage.getItem('user_name') || '');
  const [organisationName, setOrganisationName] = useState('');
  const [referralSource, setReferralSource] = useState('');

  const navigate = useNavigate();

  const handleNext = () => {
    if (step < 2) setStep(step + 1);
  };

  const handleBack = () => {
    if (step > 1) setStep(step - 1);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const handleFinish = async () => {
    const token = localStorage.getItem('authToken');

    try {
      const response = await fetch('https://platform.iwander.io/external/update-user-profile', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          organisation_name: organisationName,
          user_name: firstName,
        }),
      });
      const data = await response.json();

      if (response.ok) {
        localStorage.setItem('authToken', data.token);
        localStorage.setItem('organisation_id', data.organisation_id);  
        navigate('/docs/api-reference')

        }
    } catch {
      console.log('An error occurred.');
    }
  };



  return (
    <Container>
      <OnboardingBox>
        {/* Progress Bar */}
        <ProgressBar>
          <ProgressLine active={step >= 1} />
          <ProgressLine active={step >= 2} />
        </ProgressBar>

        {/* Step 2: User Info */}
        <Section visible={step === 1}>
          <Title>Welcome to the iWander Platform</Title>
          <InputContainer>
            <SectionTitle>What's your name</SectionTitle>
            <StyledTextField
              fullWidth
              placeholder="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              variant="outlined"
              size="small"
            />
          </InputContainer>

          <InputContainer>
            <SectionTitle>What's your organisation name</SectionTitle>
            <StyledTextField
              fullWidth
              placeholder="Organisation Name"
              value={organisationName}
              onChange={(e) => setOrganisationName(e.target.value)}
              variant="outlined"
              size="small"
            />
          </InputContainer>

          <InputContainer>
            <SectionTitle>How did you hear about us?</SectionTitle>
            <StyledSelect
              value={value}
              onChange={(e) => setValue(e.target.value)}
            >
              <option value="" disabled>
                Select an option
              </option>
              <option value="Google">Google</option>
              <option value="Friends">Friends</option>
              <option value="Social Media">Social Media</option>
              <option value="Other">Other</option>
            </StyledSelect>
          </InputContainer>
        </Section>


        {/* Step 1: Select Option */}
        <Section visible={step === 2}>
          <Title>Which best describes who you are?</Title>
          <Subtitle>We will use this to personalize your experience</Subtitle>
          <OptionsContainer>
            {['Personal Use', 'Creator', 'Content Business', 'Voice Actor', 'Engineer', 'Marketer', 'Education', 'Other'].map((option) => (
              <Option
                key={option}
                selected={selectedOption === option}
                onClick={() => handleOptionClick(option)}
              >
                {option}
              </Option>
            ))}
          </OptionsContainer>
        </Section>

        <NavigationButtons>
          {/* Back Button */}
          <Button onClick={handleBack} disabled={step === 1}>
            Back
          </Button>

          {/* Conditional Next/Finish Button */}
          {step === 1 ? (
            <Button onClick={handleNext}>
              Next
            </Button>
          ) : (
            <Button onClick={handleFinish}>
              Finish
            </Button>
          )}
        </NavigationButtons>
      </OnboardingBox>
    </Container>
  );
};

export default OnboardingPage;
import React, { useState, useEffect } from "react";
import { Button, TextField, Switch, FormControlLabel, Slider, RadioGroup, FormControl, Radio, MenuItem, Select, InputLabel, Chip, Box } from "@mui/material";
import LoadingSpinner from "../Loading";
import Notification from "../Notification";
import styled from "styled-components";
import { fadeIn, fadeOut } from "../Animations";

const PostChatbotCustomizer = () => {
  // State for customization options
  const [customization, setCustomization] = useState({
    chatBgColor: "#007BFF",
    chatTextColor: "#FFFFFF",
    widgetBgColor: "#E8E8E8",
    widgetTextColor: "#000000",
    promptBgColor: "#FFFFFF",
    promptTextColor: "#000000",
    ticketBgColor: "#FFFFFF",
    ticketTextColor: "#000000",
    bubbleText: "Hi, I'm your travel assistant!",
    introText: "How can I help you today?",
    userPrompts: ["Find a Hotel", "Destination Ideas", "Deals & Offers", "Help with My Booking"],
    headerImageUrl: "",
    iconUrl: "",
  });
  const [activeTab, setActiveTab] = useState("Widget");
  const [orgId, setOrgId] = useState(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState(null);
  const [notificationType, setNotificationType] = useState("success"); // Track notification type (success or error)



  useEffect(() => {
    const fetchChatbotStyle = async () => {
      setLoading(true);
      const token = localStorage.getItem("authToken");

      try {
        const response = await fetch("https://platform.iwander.io/agent_setup/get-post-chatbot-style", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          const styles = data.chatbot_styles;
          const config = data.post_booking_agent;

          setCustomization({
            chatBgColor: styles.chatBgColor || "#0d1b4e",
            widgetBgColor: styles.widgetBgColor || "#0d1b4e",
            chatTextColor: styles.chatTextColor || "#FFFFFF",
            widgetTextColor: styles.widgetTextColor || "#000000",
            promptBgColor: styles.promptBgColor || "#FFFFFF",
            promptTextColor: styles.promptTextColor || "#0d1b4e",
            ticketBgColor: styles.ticketBgColor || "#0d1b4e",
            ticketTextColor: styles.ticketTextColor || "#FFFFFF",
            bubbleText: config.bubble_text || "Hi, I'm your travel assistant!",
            introText: config.intro_text || "How can I help you today?",
            userPrompts: config.user_prompts || ["Find a Hotel", "Destination Ideas", "Deals & Offers", "Help with My Booking"],
            headerImageUrl: styles.header_image_url || "",
            iconUrl: styles.icon_url || "",
          });
        } else {
          console.error("Failed to fetch chatbot styles");
        }
      } catch (error) {
        console.error("Error fetching chatbot styles:", error);
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchChatbotStyle();
  }, []);

  const handleChange = (e, key) => {
    const { value } = e.target;
    setCustomization((prev) => ({ ...prev, [key]: value }));
  };

  const handlePromptChange = (index, value) => {
    const updatedPrompts = [...customization.userPrompts];
    updatedPrompts[index] = value;
    setCustomization((prev) => ({ ...prev, userPrompts: updatedPrompts }));
  };

  const handleSave = async () => {
    const token = localStorage.getItem("authToken");
    const payload = {
      chatbot_styles: {
        chatBgColor: customization.chatBgColor,
        widgetBgColor: customization.widgetBgColor,
        chatTextColor: customization.chatTextColor,
        widgetTextColor: customization.widgetTextColor,
        promptBgColor: customization.promptBgColor,
        promptTextColor: customization.promptTextColor,
        ticketBgColor: customization.ticketBgColor,
        ticketTextColor: customization.ticketTextColor,
        header_image_url: customization.headerImageUrl,
        icon_url: customization.iconUrl,
      },
      post_booking_agent: {
        bubble_text: customization.bubbleText,
        intro_text: customization.introText,
        user_prompts: customization.userPrompts,
      },
    };

    try {
      const response = await fetch("https://platform.iwander.io/agent_setup/update-post-chatbot-style", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
        setOrgId(data.org_id);
        setModalVisible(true); // Show the modal
        setNotification("🚀 Changes saved successfully!");
        setNotificationType("success"); // Set notification type to success
      } else {
        setNotification("⚠️ Failed to save changes. Please try again.");
        setNotificationType("error"); // Set notification type to error
      }
    } catch (error) {
      console.error("Error saving customization:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const removeNotification = () => {
    setNotification(null); // Remove the notification
  };


  return (
    <FlexWrapper>
      {notification && (
        <Notification
          message={notification}
          type={notificationType} // Pass the notification type
          onRemove={removeNotification}
        />
      )}

      <LoadingSpinner loading={loading} />
      <Container>

        {/* Colors Section */}
        <Section>
          <SubHeader style={{ marginBottom: "20px" }}> Colors</SubHeader>
          <Row style={{ marginBottom: "5px" }}>
            <ColorInput>
              <input type="color" value={customization.widgetBgColor} onChange={(e) => handleChange(e, "widgetBgColor")} />
            </ColorInput>
            <ColorInput>
              <input type="color" value={customization.chatBgColor} onChange={(e) => handleChange(e, "chatBgColor")} />
            </ColorInput>
            <ColorInput>
              <input type="color" value={customization.promptBgColor} onChange={(e) => handleChange(e, "promptBgColor")} />
            </ColorInput>
            <ColorInput>
              <input type="color" value={customization.ticketBgColor} onChange={(e) => handleChange(e, "ticketBgColor")} />
            </ColorInput>
          </Row>

          <Row style={{ marginBottom: "15px" }}>
            <ColorInput>
              <ConfigLabel>Widget Background</ConfigLabel>
            </ColorInput>
            <ColorInput>
              <ConfigLabel>Chat Bubble Background</ConfigLabel>
            </ColorInput>
            <ColorInput>
              <ConfigLabel>Prompt Suggestion Background</ConfigLabel>
            </ColorInput>
            <ColorInput>
              <ConfigLabel>Ticket Background</ConfigLabel>
            </ColorInput>
          </Row>

          <Row style={{ marginBottom: "5px" }}>
            <ColorInput>
              <input type="color" value={customization.widgetTextColor} onChange={(e) => handleChange(e, "widgetTextColor")} />
            </ColorInput>
            <ColorInput>
              <input type="color" value={customization.chatTextColor} onChange={(e) => handleChange(e, "chatTextColor")} />
            </ColorInput>
            <ColorInput>
              <input type="color" value={customization.promptTextColor} onChange={(e) => handleChange(e, "promptTextColor")} />
            </ColorInput>
            <ColorInput>
              <input type="color" value={customization.ticketTextColor} onChange={(e) => handleChange(e, "ticketTextColor")} />
            </ColorInput>
          </Row>
          <Row style={{ marginBottom: "10px" }}>
            <ColorInput>
              <ConfigLabel>Widget Text Color</ConfigLabel>
            </ColorInput>
            <ColorInput>
              <ConfigLabel>Chat Text Color</ConfigLabel>
            </ColorInput>
            <ColorInput>
              <ConfigLabel>Prompt Text Color</ConfigLabel>
            </ColorInput>
            <ColorInput>
              <ConfigLabel>Ticket Text Color</ConfigLabel>
            </ColorInput>
          </Row>
        </Section>

        {/* Texts Section */}
        <Section>
          <SubHeader>Texts</SubHeader>
          <InputRow>
            <Label>Bubble Text</Label>
            <StyledTextField
              fullWidth
              type="text"
              variant="outlined"
              size="small"
              value={customization.bubbleText}
              onChange={(e) => handleChange(e, "bubbleText")} />
          </InputRow>
          <InputRow>
            <Label>Intro Text</Label>
            <StyledTextField
              fullWidth
              type="text"
              variant="outlined"
              size="small"
              value={customization.introText} onChange={(e) => handleChange(e, "introText")} />
          </InputRow>
        </Section>

        {/* Images Section */}
        <Section>
          <SubHeader>Images</SubHeader>
          <InputRow>
            <Label>Header Image URL</Label>
            <StyledTextField
              fullWidth
              type="text"
              variant="outlined"
              size="small"
              value={customization.headerImageUrl} onChange={(e) => handleChange(e, "headerImageUrl")} />
          </InputRow>
          <InputRow>
            <Label>Icon Image URL</Label>
            <StyledTextField
              fullWidth
              type="text"
              variant="outlined"
              size="small"
              value={customization.iconUrl} onChange={(e) => handleChange(e, "iconUrl")} />
          </InputRow>
        </Section>

        {/* Prompts Section */}
        <Section>
          <SubHeader>User Prompts</SubHeader>
          {customization.userPrompts.map((prompt, index) => (
            <InputRow key={index}>
              <Label>{`Prompt ${index + 1}`}</Label>
              <StyledTextField
                fullWidth
                type="text"
                variant="outlined"
                size="small"
                value={prompt}
                onChange={(e) => handlePromptChange(index, e.target.value)}
              />
            </InputRow>
          ))}
        </Section>
      </Container>
      {/* Modal */}
      <ModalOverlay isVisible={isModalVisible}>
        <ModalContent>
          <h3>Script Link</h3>
          <p>Copy and paste the following script into your website:</p>
          <pre style={{ textWrap: "auto" }}>
            {`<script src="https://platform.iwander.io/pre-book-widget.js?id=${orgId}"></script>`}
          </pre>
          <ModalCloseButton onClick={() => setModalVisible(false)}>
            Close
          </ModalCloseButton>
        </ModalContent>
      </ModalOverlay>

      <NewSectionWrapper>
        <NewSection>
          <TabsContainer>
            <Tab
              isActive={activeTab === "Widget"}
              onClick={() => setActiveTab("Widget")}
            >
              Widget
            </Tab>
            <Tab
              isActive={activeTab === "Intro"}
              onClick={() => setActiveTab("Intro")}
            >
              Intro
            </Tab>
            <Tab
              isActive={activeTab === "Chat"}
              onClick={() => setActiveTab("Chat")}
            >
              Chat
            </Tab>
            <Tab
              isActive={activeTab === "Tickets"}
              onClick={() => setActiveTab("Tickets")}
            >
              Tickets
            </Tab>
          </TabsContainer>

          <TabContent>
            {activeTab === "Widget" && (
              <div style={{ textAlign: "center", alignSelf: "center", width: "75%" }}>
                {/* Widget Preview */}
                <div style={{ position: "relative", padding: "20px" }}>
                  {/* Bubble Banner */}
                  <div
                    style={{
                      position: "absolute",
                      bottom: "85px",
                      right: "45px",
                      backgroundColor: customization.widgetBgColor,
                      color: customization.widgetTextColor,
                      padding: "10px 15px",
                      borderRadius: "8px",
                      boxShadow: "0 2px 6px rgba(0, 0, 0, 0.2)",
                      fontSize: "14px",
                      fontWeight: "bold",
                      maxWidth: "250px",
                      cursor: "pointer",
                    }}
                  >
                    {customization.bubbleText}
                    {/* Close Button */}
                    <button
                      style={{
                        position: "absolute",
                        top: "-12px",
                        right: "-12px",
                        background: customization.widgetTextColor,
                        border: "1px solid",
                        borderColor: customization.widgetBgColor,
                        borderRadius: "50%",
                        height: "25px",
                        width: "25px",
                        color: customization.widgetBgColor,
                        fontSize: "14px",
                        cursor: "pointer",
                      }}
                    >
                      ✖
                    </button>
                  </div>
                  {/* Chat Button */}
                  <img
                    src={customization.iconUrl || "https://platform.iwander.io/favicon.ico"}
                    alt="Chat Icon"
                    style={{
                      position: "absolute",
                      bottom: "20px",
                      right: "20px",
                      width: "60px",
                      height: "60px",
                      borderRadius: "50%",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </div>
            )}
            {activeTab === "Intro" && (
              <div
                style={{
                  width: "100%",
                  height: "89%",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "18px",
                  boxShadow: "0 2px 6px rgba(0, 0, 0, 0.2)",
                  overflow: "hidden", // Prevent stretching
                }}
              >
                {/* Header Image */}
                <div
                  style={{
                    backgroundColor: customization.chatBgColor,
                    textAlign: "left",
                    borderRadius: "15px 15px 0px 0px",
                    padding: "0px 15px",
                  }}
                >
                  <img
                    src={customization.headerImageUrl || "https://platform.iwander.io/images/logo_white.png"}
                    alt="Header Logo"
                    style={{
                      height: "40px",
                      objectFit: "contain",
                    }}
                  />
                </div>

                {/* Intro Section */}
                <div style={{ padding: "15px", flex: 1, overflowY: "auto" }}>
                  <div style={{ marginBottom: "20px" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        marginBottom: "15px",
                        flexDirection: "row",
                      }}
                    >
                      <img
                        src={customization.iconUrl || "https://platform.iwander.io/favicon.ico"}
                        alt="Bot Avatar"
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          marginRight: "10px",
                          marginLeft: "0",
                        }}
                      />
                      <div
                        style={{
                          backgroundColor: "#F1F4F8",
                          color: "#333",
                          padding: "10px 15px",
                          borderRadius: "15px",
                          maxWidth: "70%",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        {customization.introText || "Hi! How can I assist you today?"}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      gap: "10px",
                    }}
                  >
                    {(customization.userPrompts || []).map((prompt, index) => (
                      <button
                        key={index}
                        style={{
                          backgroundColor: customization.promptBgColor,
                          border: "2px solid",
                          borderColor: customization.promptTextColor,
                          color: customization.promptTextColor,
                          borderRadius: "20px",
                          padding: "8px 12px",
                          fontSize: "14px",
                          cursor: "pointer",
                          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        {prompt}
                      </button>
                    ))}
                  </div>
                </div>
                <div style={{
                  display: "flex",
                  padding: "10px",
                  background: "#fff",
                  position: "sticky",
                  bottom: 0,
                  zIndex: 1000
                }}>
                  <input
                    style={{
                      flex: 1,
                      padding: "10px",
                      borderRadius: "20px",
                      border: "2px solid",
                      borderColor: customization.chatBgColor,
                      marginRight: "5px",
                    }}
                    type="text"
                    placeholder="Type your message..."
                  />

                </div>
              </div>
            )}

            {activeTab === "Chat" && (
              <div
                style={{
                  width: "100%",
                  height: "89%",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "18px",
                  boxShadow: "0 2px 6px rgba(0, 0, 0, 0.2)",
                  overflow: "hidden", // Prevent stretching
                }}
              >
                {/* Header Image */}
                <div
                  style={{
                    backgroundColor: customization.chatBgColor,
                    textAlign: "left",
                    borderRadius: "15px 15px 0px 0px",
                    padding: "0px 15px",
                  }}
                >
                  <img
                    src={customization.headerImageUrl || "https://platform.iwander.io/images/logo_white.png"}
                    alt="Header Logo"
                    style={{
                      height: "40px",
                      objectFit: "contain",
                    }}
                  />
                </div>

                {/* Chat Section */}
                <div style={{ padding: "15px", flex: 1, overflowY: "auto" }}>
                  {[
                    { sender: "bot", text: "Hi! How can I assist you today?" },
                    { sender: "user", text: "Tell me more about your services." },
                    { sender: "bot", text: "Sure! We provide personalized travel assistance." },
                  ].map((msg, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        marginBottom: "15px",
                        flexDirection: msg.sender === "user" ? "row-reverse" : "row",
                      }}
                    >
                      {msg.sender === "bot" && (
                        <img
                          src={customization.iconUrl || "https://platform.iwander.io/favicon.ico"}
                          alt="Bot Avatar"
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                            marginRight: msg.sender === "user" ? "0" : "10px",
                            marginLeft: msg.sender === "user" ? "10px" : "0",
                          }}
                        />
                      )}
                      <div
                        style={{
                          backgroundColor: msg.sender === "user" ? customization.chatBgColor : "#F1F4F8",
                          color: msg.sender === "user" ? customization.chatTextColor : "#333",
                          padding: "10px 15px",
                          borderRadius: "15px",
                          maxWidth: "70%",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        {msg.text.split("\n").map((line, i) => (
                          <div key={i} style={{ marginBottom: "4px" }}>
                            {line}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
                <div style={{
                  display: "flex",
                  padding: "10px",
                  background: "#fff",
                  position: "sticky",
                  bottom: 0,
                  zIndex: 1000
                }}>
                  <input
                    style={{
                      flex: 1,
                      padding: "10px",
                      borderRadius: "20px",
                      border: "2px solid",
                      borderColor: customization.chatBgColor,
                      marginRight: "5px",
                    }}
                    type="text"
                    placeholder="Type your message..."
                  />

                </div>
              </div>
            )}

            {activeTab === "Tickets" && (
              <div
                style={{
                  width: "100%",
                  height: "89%",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "18px",
                  boxShadow: "0 2px 6px rgba(0, 0, 0, 0.2)",
                  overflow: "hidden", // Prevent stretching
                }}
              >
                {/* Header Image */}
                <div
                  style={{
                    backgroundColor: customization.chatBgColor,
                    textAlign: "left",
                    borderRadius: "15px 15px 0px 0px",
                    padding: "0px 15px",
                  }}
                >
                  <img
                    src={customization.headerImageUrl || "https://platform.iwander.io/images/logo_white.png"}
                    alt="Header Logo"
                    style={{
                      height: "40px",
                      objectFit: "contain",
                    }}
                  />
                </div>

                <div style={{ padding: "15px 15px 0px 15px", flex: 1, overflowY: "auto" }}>
                  {[
                    { sender: "user", text: "Find me some great tours" },
                    { sender: "bot", text: "Here are some of our tours just for you!" },
                  ].map((msg, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        marginBottom: "15px",
                        flexDirection: msg.sender === "user" ? "row-reverse" : "row",
                      }}
                    >
                      {msg.sender === "bot" && (
                        <img
                          src={customization.iconUrl || "https://platform.iwander.io/favicon.ico"}

                          alt="Bot Avatar"
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                            marginRight: msg.sender === "user" ? "0" : "10px",
                            marginLeft: msg.sender === "user" ? "10px" : "0",
                          }}
                        />
                      )}
                      <div
                        style={{
                          backgroundColor: msg.sender === "user" ? customization.chatBgColor : "#F1F4F8",
                          color: msg.sender === "user" ? customization.chatTextColor : "#333",
                          padding: "10px 15px",
                          borderRadius: "15px",
                          maxWidth: "70%",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        {msg.text.split("\n").map((line, i) => (
                          <div key={i} style={{ marginBottom: "4px" }}>
                            {line}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}

                  {/* Tickets Section */}
                  <div
                    style={{
                      display: "flex",
                      gap: "15px",
                      overflowX: "auto", // Allow horizontal scrolling
                      overflowY: "hidden", // Prevent vertical stretching
                      padding: "15px",
                      maxwidth: "200px", // Constrain the height of the tickets section
                    }}
                  >
                    {[
                      { title: "Title", description: "Description", image_url: "https://cdn.ventrata.com/image/upload/s--mm_kr9ny--/ar_1.5,c_fill,dpr_2.0,w_400/v1681838038/p6h2ler8hut4fwvyeatp.jpg?_a=BACADKEv" },
                      { title: "Title", description: "Description", image_url: "https://cdn.ventrata.com/image/upload/s--mm_kr9ny--/ar_1.5,c_fill,dpr_2.0,w_400/v1681838038/p6h2ler8hut4fwvyeatp.jpg?_a=BACADKEv" },
                      { title: "Title", description: "Description", image_url: "https://cdn.ventrata.com/image/upload/s--mm_kr9ny--/ar_1.5,c_fill,dpr_2.0,w_400/v1681838038/p6h2ler8hut4fwvyeatp.jpg?_a=BACADKEv" },
                      { title: "Title", description: "Description", image_url: "https://cdn.ventrata.com/image/upload/s--mm_kr9ny--/ar_1.5,c_fill,dpr_2.0,w_400/v1681838038/p6h2ler8hut4fwvyeatp.jpg?_a=BACADKEv" },
                    ].map((ticket, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          minWidth: "300px",
                          maxWidth: "300px",
                          border: "1px solid #ddd",
                          borderRadius: "16px",
                          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                          backgroundColor: "#fff",
                        }}
                      >
                        <img
                          src={ticket.image_url}
                          alt={ticket.title}
                          style={{
                            objectFit: "cover",
                            height: "150px",
                            width: "100%",
                            borderRadius: "16px 16px 0px 0px",
                          }}
                        />
                        <div style={{ padding: "15px" }}>
                          <h3 style={{ fontSize: "14px", fontWeight: "600", margin: "0px" }}>{ticket.title}</h3>
                          <p style={{ fontSize: "12px", color: "#555", marginBottom: "20px" }}>{ticket.description}</p>
                          <div style={{
                            textAlign: "center",
                            backgroundColor: customization.ticketBgColor,
                            color: customization.ticketTextColor,
                            textDecoration: "none",
                            borderRadius: "25px",
                            padding: "10px 0",
                            fontWeight: "bold",
                            transition: "background 0.3s",
                            cursor: "pointer"
                          }}>
                            <a
                              href="https://www.iwander.io"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                textAlign: "center",
                                color: customization.ticketTextColor,
                                textDecoration: "none",
                                borderRadius: "25px",
                                padding: "10px 0",
                                fontWeight: "bold",
                                transition: "background 0.3s",
                                cursor: "pointer"
                              }}
                            >
                              Book Now
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div style={{
                    display: "flex",
                    padding: "10px",
                    background: "#fff",
                    position: "sticky",
                    bottom: 0,
                    zIndex: 1000
                  }}>
                    <input
                      style={{
                        flex: 1,
                        padding: "10px",
                        borderRadius: "20px",
                        border: "2px solid",
                        borderColor: customization.chatBgColor,
                        marginRight: "5px",
                      }}
                      type="text"
                      placeholder="Type your message..."
                    />

                  </div>

                </div>
              </div>
            )}

          </TabContent>

        </NewSection>
        <ButtonGroup>
          <StyledButton variant="contained" onClick={handleSave}>Save Changes</StyledButton>
        </ButtonGroup>
      </NewSectionWrapper>
    </FlexWrapper>

  );
};

const FlexWrapper = styled.div`
  display: flex;
  gap: 20px;
  overflow: hidden;
  position: relative;
  animation: 1s ${fadeIn} ease-out;
`;

const NewSectionWrapper = styled.div`
  flex: 2; /* Takes up 33% of the width */
  padding: 0px;
  overflow: hidden;
  max-height: 86vh;
`;

const NewSection = styled.div`
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 0px;
  overflow: hidden;
  height: 78vh;
`;

const TabsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
`;

const Tab = styled.button`
  flex: 1;
  padding: 10px 15px;
  background-color: transparent;
  color: ${({ isActive, theme }) => (isActive ? theme.primary : theme.secondaryText)};
  border-bottom: ${({ isActive, theme }) => (isActive ? `2px solid ${theme.primary}` : "none")};
  border-top: none; 
  border-left: none; 
  border-right: none; 
  outline: none;
  cursor: pointer;
  transition: background 0.3s, border 0.3s;

  &:hover {
    background: ${({ theme }) => theme.hover};
    border: ${({ isActive, theme }) => (isActive ? `2px solid ${theme.hover}` : `2px solid ${theme.hover}`)};
  }
`;


const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    color: theme.text, // Set the text color
    fontSize: "12px",
    borderRadius: "10px",
    "& fieldset": {
      borderColor: theme.border, // Set the border color
    },
    "&:hover fieldset": {
      borderColor: theme.primary, // Set the hover border color
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.primary, // Set the focus border color
    },
  },
  "& .MuiInputBase-input": {
    color: theme.text, // Set the input text color
  },
}));

const TabContent = styled.div`
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: baseline; /* Center vertically */
  height: 100%; /* Make it take the full height */
  max-height: 80vh;
  background: #fff;
  border-radius: 15px;
  padding: 5px 20px 0px 20px;
`;

const Container = styled.div`
  flex: 4;
  overflow: scroll;
  max-height: 85vh;
  padding-right: 10px;
  border-radius: 10px;
`;

const Header = styled.h1`
  text-align: center;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.primary};
`;

const Section = styled.div`
  margin-bottom: 30px;
  padding-bottom: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.border};
`;

const SubHeader = styled.h3`
  color: ${({ theme }) => theme.primary};
  margin-bottom: 15px;
`;


const Row = styled.div`
  display: flex;
  gap: 15px;
`;

const ColorInput = styled.div`
  flex: 1;
  text-align: center;
`;

const InputRow = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  margin-top: 5px;
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 600;
  color: ${({ theme }) => theme.secondaryText};
`;

const ConfigLabel = styled.label`
  display: block;
  margin-top: 5px;
  font-size: 11px;
  margin-bottom: 5px;
  font-weight: 500;
  color: ${({ theme }) => theme.secondaryText};
`;


const Input = styled.input`
  padding: 10px;
  width: -webkit-fill-available;
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.cardBackground};
  color: ${({ theme }) => theme.cardBackground};
`;

const ButtonGroup = styled.div`
  margin-top: 3vh; 
  display: flex;
  justify-content: flex-end; 
  gap: 10px; 
`;


const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.primary} !important;
  color: white;
  margin-right: 10px !important; /* Optional if needed */
  border-radius: 5px !important; /* Match AddButton's border radius */
  font-weight: 600 !important; /* Match AddButton's font weight */
  text-transform: none !important; /* Remove default Material-UI uppercase transformation */

  &:hover {
    background-color: ${({ theme }) => theme.primaryHover || theme.primary} !important;
  }
`;


const SaveButton = styled.button`
  width: 100%;
  padding: 12px 20px;
  background-color: ${({ theme }) => theme.primary};
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: ${({ isVisible }) => (isVisible ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.text};
  border: 3px solid ${({ theme }) => theme.border};
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  text-align: center;
`;

const ModalCloseButton = styled.button`
  background: ${({ theme }) => theme.primary};
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
`;

export default PostChatbotCustomizer;
import React, { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './ProductEditModal.css';

const ProductEditModal = ({ product, onSave, onCancel, onChange, fetchProducts }) => {
  const textareaRef = useRef(null);
  const token = localStorage.getItem("authToken");

  const handleDelete = async () => {
    const payload = {
      product_id: product._id,
    };

    try {
      const response = await fetch('https://platform.iwander.io/products/delete-product', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        await fetchProducts();
        onCancel(); 
      } else {
        console.error("Failed to update bus stop.");
      }
    } catch (error) {
      console.error("Error updating bus stop:", error);
    }
  };

  return (
    <div className="modal">
      <div className="modal-box-content">
        <div className="modal-close-button" onClick={onCancel}>
          <FontAwesomeIcon icon={faTimes} />
        </div>

        <div className="edit-field">
          <label>Title:</label>
          <textarea
            ref={textareaRef}
            type="text"
            name="title"
            value={product.title}
            onChange={onChange}
          />
        </div>
        <div className="edit-field">
          <label>Description:</label>
          <textarea
            ref={textareaRef}
            type="text"
            name="description"
            value={product.description}
            onChange={onChange}
          />
        </div>
        <div className="edit-field">
          <label>Location:</label>
          <textarea
            ref={textareaRef}
            type="text"
            name="location"
            value={product.location}
            onChange={onChange}
        />
        </div>
        <div className="edit-field">
          <label>Image URL:</label>
          <textarea
            ref={textareaRef}
            type="text"
            name="imageUrl"
            value={product.image_url}
            onChange={onChange}
          />
        </div>
        <div className="edit-field">
          <label>Product URL:</label>
          <textarea
            ref={textareaRef}
            type="text"
            name="productUrl"
            value={product.product_url}
            onChange={onChange}
          />
        </div>
        <div className="modal-actions">
          <button className="delete-button" onClick={handleDelete}>Delete</button>
          <button className="save-button" onClick={onSave}>Save</button>
        </div>
      </div>
    </div>
  );
};

export default ProductEditModal;
import React, { useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './globalStyles';
import { lightTheme, darkTheme } from './themes';
import DocsLayout from './components/api_reference/DocsLayout';
import AccountPage from './components/account/AccountPage';
import Login from './components/Login';
import PreChatbotPage from './components/PreChatbotPage';
import PostChatbotPage from './components/PostChatbotPage';
import Dashboard from './components/internal_analytics/Dashboard';
import Configuration from './components/configuration/Configuration';
import ProtectedRoute from './components/ProtectedRoute';
import OnboardingPage from './components/account/OnboardingPage';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import styled from 'styled-components';
import Analytics from './components/external_analytics/Analytics';
import StripeConfirmation from './components/StripeConfirmation';


const AppContainer = styled.div`
  display: flex;
  margin-top: 60px;
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  overflow: auto;
`;

function App() {
  const [theme, setTheme] = useState(() => localStorage.getItem("theme") || "light");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isIwanderUser, setIsIwanderUser] = useState(false); // Track if user belongs to iWander
  const [loading, setLoading] = useState(true); // Add loading state to wait for token check

  // Check if the user is authenticated and determine if they are an iWander user
  useEffect(() => {
    const token = localStorage.getItem('authToken');
    const organisationId = localStorage.getItem('organisation_id');

    if (token) {
      setIsAuthenticated(true);
    }

    if (organisationId === 'iwander') {
      setIsIwanderUser(true); // Check if the user belongs to iWander
    }

    setLoading(false); // Stop the loading state after the check
  }, []);

  useEffect(() => {
    // Function to get query parameters
    const getQueryParam = (param) => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(param);
    };

    // Dynamically apply styles based on query params
    const bgColor = getQueryParam("bgColor");
    const textColor = getQueryParam("textColor");

    // Apply styles to the body or root container
    document.body.style.backgroundColor = bgColor;
    document.body.style.color = textColor;
  }, []);

  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
  };

  const currentTheme = theme === "light" ? lightTheme : darkTheme;

  const handleLogin = (token) => {
    localStorage.setItem('authToken', token);
    setIsAuthenticated(true); // Set the user as authenticated upon login
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('organisation_id'); // Remove organisation_id
    setIsAuthenticated(false); // Log the user out by removing the token
    setIsIwanderUser(false); // Reset iWander status
  };


  if (loading) {
    return <div>Loading...</div>; // Show a loading state while checking token
  }

  return (
    <ThemeProvider theme={currentTheme}>
      <>
        <GlobalStyle />
        <Router>
          <Routes>
            {/* Login Route */}
            <Route path="/login" element={<Login onLogin={handleLogin} />} />

            <Route path="/stripe-confirmation" element={<StripeConfirmation />} />
            <Route path="/onboarding" element={<OnboardingPage />} />

            <Route
              path="/configuration"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <Configuration toggleTheme={toggleTheme} theme={theme} />
                </ProtectedRoute>
              }
            />

            <Route
              path="/analytics"
              element={
                isAuthenticated && isIwanderUser ? (
                  <Dashboard toggleTheme={toggleTheme} theme={theme} />
                ) : (
                  <Navigate to="/chatbot-analytics" />
                )
              }
            />
            <Route
              path="/chatbot-analytics/*"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <Analytics toggleTheme={toggleTheme} theme={theme} />
                </ProtectedRoute>
              }
            />
            {/* Protected Routes */}
            <Route
              path="/docs/api-reference/*"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <DocsLayout toggleTheme={toggleTheme} theme={theme} />
                </ProtectedRoute>
              }
            />

            <Route
              path="/account/*"
              element={
                <ProtectedRoute>
                  <AccountPage toggleTheme={toggleTheme} theme={theme} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/pre-chatbot/*"
              element={
                <PreChatbotPage />
              }
            />

            <Route
              path="/post-chatbot/*"
              element={
                <PostChatbotPage />
              }
            />

            {/* Redirect all other routes to login if not authenticated */}
            <Route path="*" element={<Navigate to={isAuthenticated ? "/docs/api-reference" : "/login"} />} />
          </Routes>
        </Router>
      </>
    </ThemeProvider>
  );
}

export default App;
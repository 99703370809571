import React, { useState, useEffect } from "react";
import styled from "styled-components";
import LoadingSpinner from "../Loading";
import Notification from "../Notification";
import { Button, TextField, Switch, FormControlLabel, Slider, RadioGroup, FormControl, Radio, MenuItem, Select, InputLabel, Chip, Box } from "@mui/material";
import { fadeIn, fadeOut } from "../Animations";

const StyledTextField = styled(TextField)(({ theme }) => ({
    "& .MuiOutlinedInput-root": {
        color: theme.text, // Set the text color
        fontSize: "12px",
        borderRadius: "10px",
        "& fieldset": {
            borderColor: theme.border, // Set the border color
        },
        "&:hover fieldset": {
            borderColor: theme.primary, // Set the hover border color
        },
        "&.Mui-focused fieldset": {
            borderColor: theme.primary, // Set the focus border color
        },
    },
    "& .MuiInputBase-input": {
        color: theme.text, // Set the input text color
    },
}));

const PageContainer = styled.div`
  display: flex;
  color: ${({ theme }) => theme.text};
  font-size: 12px !important;
  overflow: hidden;
  height: 84vh;
  position: relative;
  animation: 1s ${fadeIn} ease-out;
`;

const DetailsPanel = styled.div`
  flex: 1;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  padding-right: 40px;
`;

const TonePanel = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
  padding-left: 40px;
  flex-direction: column;
  border-radius: 8px;
  height: 100%;
`;

const SectionTitle = styled.h3`
  margin-top: 5px;
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 600;
  color: ${({ theme }) => theme.secondaryText};
`;

const ToolContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 5px 0;
`;

const ButtonGroup = styled.div`
  margin-top: auto; 
  display: flex;
  justify-content: flex-end; 
  gap: 10px; 
`;

const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.primary} !important;
  color: white;
  margin-right: 10px !important; /* Optional if needed */
  border-radius: 5px !important; /* Match AddButton's border radius */
  font-weight: 600 !important; /* Match AddButton's font weight */
  text-transform: none !important; /* Remove default Material-UI uppercase transformation */

  &:hover {
    background-color: ${({ theme }) => theme.primaryHover || theme.primary} !important;
  }
`;


const InputContainer = styled.div`
  margin: 5px 0px 15px 0px;
`;

const SliderContainer = styled.div`
  color: ${({ theme }) => theme.text};
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const IndividualSlider = styled.div`
  color: ${({ theme }) => theme.text};
  padding: 5px;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
`;

const StyledSlider = styled(Slider)`
  & .MuiSlider-thumb {
    color: white;
    border: 2px solid ${({ theme }) => theme.primary};
  }
  & .MuiSlider-track {
    color: ${({ theme }) => theme.primary};
  }
  & .MuiSlider-rail {
    color: ${({ theme }) => theme.border};
  }
  & .MuiSlider-markLabel {
    color: ${({ theme }) => theme.text};
    font-size: 12px;
    
  }
  & .MuiSlider-mark {
    background-color: transparent;
  }
`;

const ChipListContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 10px;
`;


const Card = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.cardBackground};
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 10px;
  padding: 20px;
  gap: 10px;
  cursor: pointer;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 10px; /* Space between the title and the logo */
`;

const Logo = styled.img`
  width: 30px; /* Adjust size as needed */
  height: 30px;
  object-fit: contain;
  border-radius: 5px; /* Optional, depending on the logo style */
`;

const BadgesContainer = styled.div`
  display: flex;
  align-self: self-end;
  gap: 10px;
`;

const Badge = styled.div`
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 0.8rem;
  font-weight: bold;
  color: ${({ theme, type }) => (type === "integrated" ? theme.primary : theme.text)};
  background-color: ${({ theme, type }) =>
        type === "integrated" ? theme.primaryLight : theme.secondaryLight};
`;

const Title = styled.h3`
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0;
`;



const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: ${({ theme }) => theme.background};
  padding: 20px;
  border-radius: 20px;
  max-width: 400px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const ModalTitle = styled.h3`
  font-size: 1rem;
  margin-bottom: 20px;
`;

const ModalButtons = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 20px;
`;


const OrgSetup = () => {
    const [notification, setNotification] = useState(null);
    const [notificationType, setNotificationType] = useState("success"); // Track notification type (success or error)
    const [name, setName] = useState("");
    const [supportUrl, setSupportUrl] = useState("");
    const [orgName, setOrgName] = useState("");
    const [description, setDescription] = useState("");
    const [longDescription, setLongDescription] = useState("");
    const [guardrails, setGuardrails] = useState([""]);
    const [error, setError] = useState('');
    const [model, setModel] = useState("attractions");
    const [hotels, setHotels] = useState(false);
    const [attractions, setAttractions] = useState(false);
    const [flights, setFlights] = useState(false);
    const [experiences, setExperiences] = useState(false);
    const [friendly, setFriendly] = useState(1); // 1 is Medium by default
    const [empathy, setEmpathy] = useState(1); // 1 is Medium by default
    const [factual, setFactual] = useState(1); // 1 is Medium by default
    const [energetic, setEnergetic] = useState(1); // 1 is Medium by default
    const [length, setLength] = useState(1); // 1 is Medium by default
    const [depth, setDepth] = useState(1); // 1 is Medium by default
    const [enthusiasm, setEnthusiasm] = useState(1); // 1 is Medium by default
    const [detail, setDetail] = useState(1); // 1 is Medium by default
    const [ticketScope, setTicketScope] = useState("global"); // 'global' or 'specific'
    const [newLocation, setNewLocation] = useState(''); // Tracks input in the TextField
    const [locations, setLocations] = useState([]);
    const [loading, setLoading] = useState(false);
    const [providers, setProviders] = useState({
        freshchat: { url: "", api_key: "", channel_id: "" },
        anotherProvider: { url: "", api_key: "", channel_id: "" }, // Add other providers as needed
    });

    const [selectedProvider, setSelectedProvider] = useState("freshchat"); // Tracks the currently active provider
    const [isModalOpen, setIsModalOpen] = useState(false);

    const providerList = [
        { name: "freshchat", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIZ5xiAQuaRSMGXlp8gZlymoPAXXWcwSq8yY0b1AG0UPPhOksriM_MBP3Xiz0zq8sufCU&usqp=CAU" },
    ];

    useEffect(() => {
        const fetchPreBookConfig = async () => {
            setLoading(true);
            const token = localStorage.getItem('authToken');
            if (!token) {
                console.error('No token found');
                return;
            }

            try {
                const response = await fetch('https://platform.iwander.io/agent_setup/get-pre-booking-config', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch API keys');
                }

                const data = await response.json();
                setOrgName(data.pre_booking_agent.organisation_name);
                setDescription(data.pre_booking_agent.organisation_description);
                setLongDescription(data.pre_booking_agent.long_description);
                setName(data.pre_booking_agent.guide_name);
                setSupportUrl(data.pre_booking_agent.support_url);
                setTicketScope(data.pre_booking_agent.ticket_scope)
                setLocations(data.pre_booking_agent.locations)
                setGuardrails(data.pre_booking_agent.guardrails)
                setFriendly(data.pre_booking_agent.tone_of_voice.friendly)
                setEmpathy(data.pre_booking_agent.tone_of_voice.empathy)
                setFactual(data.pre_booking_agent.tone_of_voice.factual)
                setEnergetic(data.pre_booking_agent.tone_of_voice.energetic)
                setLength(data.pre_booking_agent.tone_of_voice.length)
                setDepth(data.pre_booking_agent.tone_of_voice.depth)
                setEnthusiasm(data.pre_booking_agent.tone_of_voice.enthusiasm)
                setDetail(data.pre_booking_agent.tone_of_voice.detail)
                const purposes = data.pre_booking_agent.purpose || [];
                setAttractions(purposes.includes('attractions'));
                setHotels(purposes.includes('hotels'));
                setFlights(purposes.includes('flights'));
                setExperiences(purposes.includes('experiences'));
                setProviders(data.external_support || {});
            } catch (error) {
                console.error('Error fetching API keys:', error);
            } finally {
                setLoading(false); // Stop loading
            }
        };

        fetchPreBookConfig();
    }, []);

    const professionalMarks = [
        { value: 0, label: "Professional" },
        { value: 1, label: "Neutral" },
        { value: 2, label: "Friendly" },
    ];
    const empathyMarks = [
        { value: 0, label: "Empathetic" },
        { value: 1, label: "Neutral" },
        { value: 2, label: "Direct" },
    ];
    const factMarks = [
        { value: 0, label: "Factual" },
        { value: 1, label: "Neutral" },
        { value: 2, label: "Inspirational" },
    ];
    const energyMarks = [
        { value: 0, label: "Calm" },
        { value: 1, label: "Neutral" },
        { value: 2, label: "Energetic" },
    ];

    const lengthMarks = [
        { value: 0, label: "Short" },
        { value: 1, label: "Medium" },
        { value: 2, label: "Long" },
    ];
    const depthMarks = [
        { value: 0, label: "Minimal" },
        { value: 1, label: "Balanced" },
        { value: 2, label: "Comprehensive" },
    ];
    const enthusiasMarks = [
        { value: 0, label: "Low" },
        { value: 1, label: "Moderate" },
        { value: 2, label: "High" },
    ];
    const detailMarks = [
        { value: 0, label: "High-Level" },
        { value: 1, label: "Moderate" },
        { value: 2, label: "In-Depth" },
    ];

    const availableLocations = [
        'New York', 'London', 'Paris', 'Tokyo', 'Sydney', 'Los Angeles', 'Berlin', 'Rome'
    ];

    const handleScopeChange = (e) => {
        setTicketScope(e.target.value);
        setLocations([]); // Clear locations when scope changes
    };

    // Handle text input changes
    const handleInputChange = (e) => {
        setNewLocation(e.target.value);
    };

    // Add location on Enter key press
    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && newLocation.trim() !== '') {
            // Add location if not already in the list
            if (!locations.includes(newLocation.trim())) {
                setLocations([...locations, newLocation.trim()]);
            }
            setNewLocation(''); // Clear the input field
        }
    };

    // Remove a location
    const handleDelete = (locationToDelete) => {
        setLocations(locations.filter((loc) => loc !== locationToDelete));
    };

    const handleChange = (e) => {
        const value = e.target.value;
        if (value.length <= 100) {
            setDescription(value);
            console.log(description)
            setError(''); // Clear error if input is valid
        } else {
            setError('Description must be 100 characters or less');
        }
    };

    const handleSaveChanges = async () => {
        // Construct the payload
        const payload = {
            "pre_booking":
            {
                organisation_name: orgName,
                organisation_description: description,
                long_description: longDescription,
                guide_name: name,
                support_url: supportUrl,
                purpose: [],
                ticket_scope: ticketScope,
                locations: locations,
                guardrails: guardrails,
                tone_of_voice: {
                    friendly,
                    empathy,
                    factual,
                    energetic,
                    length,
                    depth,
                    enthusiasm,
                    detail,
                },
            }
        };

        // Add selected purpose items based on the switches
        if (attractions) payload.pre_booking.purpose.push("attractions");
        if (hotels) payload.pre_booking.purpose.push("hotels");
        if (flights) payload.pre_booking.purpose.push("flights");
        if (experiences) payload.pre_booking.purpose.push("experiences");

        try {
            // Send the payload to the API
            const token = localStorage.getItem("authToken");
            const response = await fetch("https://platform.iwander.io/agent_setup/update-pre-booking-config", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(payload),
            });

            if (response.ok) {
                setNotification("🚀 Changes saved successfully!");
                setNotificationType("success"); // Set notification type to success
            } else {
                setNotification("⚠️ Failed to save changes. Please try again.");
                setNotificationType("error"); // Set notification type to error
            }

            // alert("Configuration updated successfully!");
        } catch (error) {
            console.error("Error updating configuration:", error);
            alert("Failed to update configuration. Please try again.");
        }
    };

    const removeNotification = () => {
        setNotification(null); // Remove the notification
    };

    const handleIntegrationClick = (providerName) => {
        setSelectedProvider(providerName);
        setIsModalOpen(true);
    };

    const handleSaveSupport = async () => {
        const payload = {
            provider: selectedProvider,
            url: providers[selectedProvider]?.url,
            api_key: providers[selectedProvider]?.api_key,
            channel_id: providers[selectedProvider]?.channel_id,
        };

        try {
            const token = localStorage.getItem("authToken");
            const response = await fetch("https://platform.iwander.io/agent_setup/update-customer-support", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(payload),
            });

            if (response.ok) {
                const data = await response.json();
                console.log("Support integration updated successfully:", data);
                setIsModalOpen(false); // Close modal after successful save
            } else {
                console.error("Failed to update support integration:", await response.text());
            }
        } catch (error) {
            console.error("Error while updating support integration:", error);
        }
    };

    const handleDeactivateSupport = async () => {
        const payload = {
            provider: "deactivate",
            url: "",
            api_key: "",
            channel_id: "",
        };
    
        try {
            const token = localStorage.getItem("authToken");
            const response = await fetch("https://platform.iwander.io/agent_setup/update-customer-support", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(payload),
            });
    
            if (response.ok) {
                const data = await response.json();
                console.log("Support integration deactivated successfully:", data);
                setIsModalOpen(false); // Close modal after successful deactivation
            } else {
                console.error("Failed to deactivate support integration:", await response.text());
            }
        } catch (error) {
            console.error("Error while deactivating support integration:", error);
        }
    };
    
    return (
        <PageContainer>
            {notification && (
                <Notification
                    message={notification}
                    type={notificationType} // Pass the notification type
                    onRemove={removeNotification}
                />
            )}

            <LoadingSpinner loading={loading} />
            {/* Details Panel */}
            <DetailsPanel>
                <InputContainer>
                    <SectionTitle>Organisation Name</SectionTitle>
                    <StyledTextField
                        fullWidth
                        placeholder="Name of your organisation"
                        value={orgName}
                        onChange={(e) => setOrgName(e.target.value)}
                        variant="outlined"
                        size="small"
                    />
                </InputContainer>

                <InputContainer>
                    <SectionTitle>Guide Name</SectionTitle>
                    <StyledTextField
                        fullWidth
                        placeholder="Enter the name of your guide"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        variant="outlined"
                        size="small"
                    />
                </InputContainer>

                <InputContainer>
                    <SectionTitle>Short Organisation Description</SectionTitle>
                    <StyledTextField
                        fullWidth
                        placeholder="Enter organisation description (max 100 words)"
                        value={description}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                    />
                </InputContainer>

                <InputContainer>
                    <SectionTitle>Long Organisation Description</SectionTitle>
                    <StyledTextField
                        fullWidth
                        placeholder="Enter any other information relating to your organisation to add to the agent's knowledge base"
                        value={longDescription}
                        onChange={(e) => setLongDescription(e.target.value)}
                        variant="outlined"
                        size="small"
                        InputProps={{
                            sx: {
                                minHeight: '150px', alignItems: "baseline",
                            },
                        }}

                    />
                </InputContainer>

                <InputContainer>
                    <SectionTitle>Support/FAQ Fallback URL</SectionTitle>
                    <StyledTextField
                        fullWidth
                        placeholder="Enter your support of FAQ URL"
                        value={supportUrl}
                        onChange={(e) => setSupportUrl(e.target.value)}
                        variant="outlined"
                        size="small"
                    />
                </InputContainer>

                <InputContainer>
                    <SectionTitle>Support Chatbot Integration</SectionTitle>

                    {providerList.map((provider) => (
                        <Card key={provider.name} onClick={() => handleIntegrationClick(provider.name)}>
                            <Header>
                                <Logo src={provider.logo} alt={`${provider.name} Logo`} />
                                <Title>{provider.name}</Title>
                            </Header>
                            <BadgesContainer>
                                {providers[provider.name]?.url ? (
                                    <Badge type="integrated">INTEGRATED</Badge>
                                ) : (
                                    <Badge type="not-integrated">NOT INTEGRATED</Badge>
                                )}
                            </BadgesContainer>
                        </Card>
                    ))}

                    {isModalOpen && (
                        <ModalOverlay>
                            <ModalContent>
                                <ModalTitle>{selectedProvider} Integration Details</ModalTitle>
                                <TextField
                                    fullWidth
                                    label="URL"
                                    variant="outlined"
                                    value={providers[selectedProvider]?.url || ""}
                                    onChange={(e) =>
                                        setProviders((prev) => ({
                                            ...prev,
                                            [selectedProvider]: {
                                                ...prev[selectedProvider],
                                                url: e.target.value,
                                            },
                                        }))
                                    }
                                    margin="normal"
                                />
                                <TextField
                                    fullWidth
                                    label="API Key"
                                    variant="outlined"
                                    value={providers[selectedProvider]?.api_key || ""}
                                    onChange={(e) =>
                                        setProviders((prev) => ({
                                            ...prev,
                                            [selectedProvider]: {
                                                ...prev[selectedProvider],
                                                api_key: e.target.value,
                                            },
                                        }))
                                    }
                                    margin="normal"
                                />
                                <TextField
                                    fullWidth
                                    label="Channel ID"
                                    variant="outlined"
                                    value={providers[selectedProvider]?.channel_id || ""}
                                    onChange={(e) =>
                                        setProviders((prev) => ({
                                            ...prev,
                                            [selectedProvider]: {
                                                ...prev[selectedProvider],
                                                channel_id: e.target.value,
                                            },
                                        }))
                                    }
                                    margin="normal"
                                />
                                <ModalButtons>
                                    <Button variant="outlined" onClick={() => setIsModalOpen(false)}>
                                        Cancel
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={handleDeactivateSupport}
                                    >
                                        Deactivate
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSaveSupport}
                                    >
                                        Save
                                    </Button>
                                </ModalButtons>
                            </ModalContent>
                        </ModalOverlay>
                    )}
                </InputContainer>

            </DetailsPanel>
            <TonePanel>

                <ToolContainer>
                    <SectionTitle>Purpose</SectionTitle>
                    <FormControlLabel
                        style={{ marginLeft: '0px' }}
                        control={
                            <Switch
                                checked={attractions}
                                onChange={(e) => setAttractions(e.target.checked)}
                                color="primary"

                            />
                        }
                        label="Attractions"
                        sx={{
                            '& .MuiFormControlLabel-label': {
                                fontSize: '14px', // Adjust the font size
                            },
                        }}
                    />
                    <FormControlLabel
                        style={{ marginLeft: '0px' }}
                        control={
                            <Switch
                                checked={hotels}
                                onChange={(e) => setHotels(e.target.checked)}
                                color="primary"
                            />
                        }
                        label="Hotels"
                        sx={{
                            '& .MuiFormControlLabel-label': {
                                fontSize: '14px', // Adjust the font size
                            },
                        }}

                    />
                    <FormControlLabel
                        style={{ marginLeft: '0px' }}
                        control={
                            <Switch
                                checked={flights}
                                onChange={(e) => setFlights(e.target.checked)}
                                color="primary"
                            />
                        }
                        label="Flights"
                        sx={{
                            '& .MuiFormControlLabel-label': {
                                fontSize: '14px', // Adjust the font size
                            },
                        }}

                    />
                    <FormControlLabel
                        style={{ marginLeft: '0px' }}
                        control={
                            <Switch
                                checked={experiences}
                                onChange={(e) => setExperiences(e.target.checked)}
                                color="primary"
                            />
                        }
                        label="Experiences"
                        sx={{
                            '& .MuiFormControlLabel-label': {
                                fontSize: '14px', // Adjust the font size
                            },
                        }}

                    />
                </ToolContainer>

                <InputContainer>
                    <SectionTitle>Coverage Areas</SectionTitle>
                    <FormControl component="fieldset">
                        <RadioGroup row value={ticketScope} onChange={handleScopeChange}>
                            <FormControlLabel
                                value="global"
                                control={<Radio />}
                                label="Global"
                                sx={{
                                    '& .MuiFormControlLabel-label': {
                                        fontSize: '14px', // Adjust the font size
                                    },
                                }}
                            />
                            <FormControlLabel
                                value="specific"
                                control={<Radio />}
                                label="Specific Regions or Cities"
                                sx={{
                                    '& .MuiFormControlLabel-label': {
                                        fontSize: '14px', // Adjust the font size
                                    },
                                }}
                            />
                        </RadioGroup>
                    </FormControl>
                </InputContainer>

                {/* Text Input for Adding Destinations */}
                {ticketScope === 'specific' && (
                    <InputContainer>
                        <SectionTitle>Enter Destinations</SectionTitle>
                        <StyledTextField
                            fullWidth
                            placeholder="Type a city or region and press Enter..."
                            value={newLocation}
                            onChange={handleInputChange}
                            onKeyPress={handleKeyPress}
                            variant="outlined"
                            size="small"
                        />

                        {/* List of Added Destinations */}
                        <ChipListContainer>
                            {locations.map((location, index) => (
                                <Chip
                                    key={index}
                                    label={location}
                                    onDelete={() => handleDelete(location)}
                                    color="primary"
                                />
                            ))}
                        </ChipListContainer>
                    </InputContainer>
                )}

                <ButtonGroup>
                    {/* <StyledButton variant="outlined">Reset</StyledButton> */}
                    <StyledButton variant="contained" onClick={handleSaveChanges}>Save Changes</StyledButton>
                </ButtonGroup>

            </TonePanel>
        </PageContainer>
    );
}
export default OrgSetup;
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTable } from 'react-table';

// Styled container
const MainContainer = styled.div`
  display: flex;
  gap: 20px;
  height: 80vh;
`;

// Left section for the table
const TableContainer = styled.div`
  flex: 4;
  background-color: ${({ theme }) => theme.cardBackground};
  overflow-x: auto; /* Enables horizontal scrolling */
  margin-top: 20px;
`;

const ResizableSidebar = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: ${({ width }) => width}px; /* Sidebar width is dynamic */
  height: 100vh;
  background-color: ${({ theme }) => theme.background};
  border-left: 0.5px solid ${({ theme }) => theme.border};
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  z-index: 1000;
  transition: none; /* Disable transition for smooth resizing */
`;

const Divider = styled.div`
  position: absolute;
  top: 0;
  left: -5px;
  width: 10px; /* Invisible drag area */
  height: 100%;
  cursor: ew-resize; /* Horizontal resize cursor */
  background-color: transparent;
  z-index: 1001; /* Place above sidebar content */
`;
// Right section for the stats
const StatsContainer = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.cardBackground};
  border-radius: 8px;
  padding: 20px;
`;

const SidebarContent = styled.div`
  display: flex;
  flex-direction: row; /* Arrange input/output and metadata side by side */
  height: 96vh; /* Ensure content stretches to the full height */
  font-family: 'Courier New', Courier, monospace; /* Optional for a "code block" look */
  padding-top: 20px;
`;

const ContentSection = styled.div`
  flex: 3; /* Main input/output content takes more space */
  overflow-y: auto; /* Allow scrolling for long content */
  padding: 20px 30px;
`;

const MetadataSection = styled.div`
  flex: 2; /* Metadata takes less space */
  overflow-y: auto; /* Allow scrolling for metadata if necessary */
  background-color: ${({ theme }) => theme.backgroundLight};
  padding: 20px 30px;
`;

const MetadataList = styled.ul`
  list-style-type: none;
  padding: 0;

  li {
    margin-bottom: 10px;
    overflow-wrap: anywhere;

    SectionTitle {
      font-weight: bold;
    }
  }
`;

// const Sidebar = styled.div`
//   position: fixed;
//   top: 0;
//   right: ${({ visible }) => (visible ? '0' : '-60vw')};
//   width: 60vw;
//   height: 100vh;
//   background-color: ${({ theme }) => theme.background};
//   box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
//   transition: right 0.3s ease-in-out;
//   display: flex;
//   flex-direction: column;
//   overflow-y: auto;
//   z-index: 1000;
//   border-left: 1px solid ${({ theme }) => theme.border};

// `;

const CollapseContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 5.5vh;
`;

const CollapseButton = styled.button`
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.primary};
  border: none;
  border-radius: 10%;
  margin-left: 30px;
  font-size: 1.5rem;
  width: 30px;
  height: 30px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const MessageContainer = styled.div`
  margin-bottom: 20px;
  padding: 15px;
  background-color: ${({ theme }) => theme.backgroundLight};
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 8px;
  white-space: pre-wrap; /* Ensures \n is rendered as a new line */
  font-family: 'Courier New', Courier, monospace; /* Optional for a "code block" look */
  font-size: 14px;
  line-height: 1.5;
`;

const SectionTitle = styled.h4`
  margin-bottom: 10px;
  font-weight: bold;
  color: ${({ theme }) => theme.primary};
`;

const InputOutputSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

// Styled table
const StyledTable = styled.table`
    width: 100%;
    min-width: 1000px; /* Ensures table doesn't shrink below this width */
    border-collapse: collapse;
    text-align: left;

    thead {
        position: sticky;
        top: 0; /* Sticks the header to the top of the table */
        z-index: 2; /* Ensure it appears above other elements */
      }
    
  th {
    padding: 12px 15px;
    height: 25px; /* Ensures uniform row height */
    vertical-align: middle; /* Align text vertically to the middle */
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflowing content */
    text-overflow: ellipsis; /* Add ellipsis to the end of the content */
    max-width: 300px; /* Adjust width for ellipsis effect */  
    background-color: ${({ theme }) => theme.tableHeader};
    color: #a0a0ab;
    font-weight: 600;
    font-size: .875rem;
    line-height: 1.25rem;
  }

  td {
    vertical-align: middle; /* Align text vertically to the middle */
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflowing content */
    text-overflow: ellipsis; /* Add ellipsis to the end of the content */
    max-width: 300px; /* Adjust width for ellipsis effect */  
    font-size: .875rem;
    line-height: 1.25rem;
    padding: 1rem;
    cursor: pointer;
  }

  tr {
    &:hover {
        background-color: ${({ theme }) => theme.tableHeader};
      }    
      border-bottom: 0.5px solid ${({ theme }) => theme.border};


  }

`;

const PlaceholderStats = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  h3 {
    margin: 0;
    font-size: 1.2rem;
    font-weight: bold;
  }

  p {
    margin: 0;
    font-size: 1rem;
    color: ${({ theme }) => theme.textSecondary};
  }

  .stat-value {
    font-size: 1.5rem;
    font-weight: bold;
  }
`;



const ChatbotRequestTable = ({
    detailedData,
    totalChatRequests,
    totalAudioRequests,
    }) => {
    const [selectedRow, setSelectedRow] = useState(null);
    const [isSidebarVisible, setSidebarVisible] = useState(false);
    const [sidebarWidth, setSidebarWidth] = useState(1000); // Default width of 400px
    const [isResizing, setIsResizing] = useState(false); // Track resizing state
    console.log(detailedData)
    // Define table columns
    const columns = React.useMemo(
        () => [
            { Header: 'Time', accessor: 'time' },
            { Header: 'Input', accessor: 'input' },
            { Header: 'Output', accessor: 'output' }, // Ellipsis for Output column
            { Header: 'Request Location', accessor: 'request_location' },
            { Header: 'User ID', accessor: 'user_id' },
            { Header: 'Guide', accessor: 'guide' },

        ],
        []
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data: detailedData || [], 
    });

    const handleMouseDown = () => setIsResizing(true); // Start resizing
    const handleMouseUp = () => setIsResizing(false); // Stop resizing
    const handleMouseMove = (e) => {
        if (isResizing) {
            const newWidth = Math.max(600, Math.min(1000, window.innerWidth - e.clientX));
            setSidebarWidth(newWidth);
        }
    };

    useEffect(() => {
        // Attach event listeners for dragging
        if (isResizing) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
        } else {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        }

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isResizing]);

    const handleRowClick = (row) => {
        setSelectedRow(row.original);
        setSidebarVisible(true);
    };

    const closeSidebar = () => setSidebarVisible(false);

    return (
        <MainContainer>
            {/* Table Section */}
            <TableContainer>
                <StyledTable {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} onClick={() => handleRowClick(row)}>
                                    {row.cells.map((cell) => (
                                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </StyledTable>
            </TableContainer>


            {isSidebarVisible && (
                <ResizableSidebar width={sidebarWidth}>
                    {/* Draggable Divider */}
                    <Divider onMouseDown={handleMouseDown} />

                    {/* Collapsible Button */}
                    <CollapseContainer>
                        <CollapseButton onClick={closeSidebar}>≫</CollapseButton>
                    </CollapseContainer>
                    {selectedRow && (
                        <SidebarContent>
                            {/* Input/Output Section */}
                            <ContentSection>
                                <InputOutputSection>
                                    {/* Input Section */}
                                    <div>
                                        <SectionTitle>Input:</SectionTitle>
                                        <MessageContainer>
                                            {selectedRow.input}
                                        </MessageContainer>
                                    </div>

                                    {/* Output Section */}
                                    <div>
                                        <SectionTitle>Output:</SectionTitle>
                                        <MessageContainer>
                                            {selectedRow.output}
                                        </MessageContainer>
                                    </div>
                                </InputOutputSection>
                            </ContentSection>
                            {/* Metadata Section */}
                            <MetadataSection>
                                <MetadataList>
                                    {/* Loop through all top-level keys in metadata */}
                                    {Object.entries(selectedRow.metadata).map(([key, value]) => {
                                        // Handle the "context" field separately
                                        if (key === "context" && value) {
                                            return Object.entries(value).map(([contextKey, contextValue]) => (
                                                <li key={contextKey}>
                                                    <SectionTitle>{contextKey.charAt(0).toUpperCase() + contextKey.slice(1)} User Preferences</SectionTitle>
                                                    <ul>
                                                        {Object.entries(contextValue).map(([subKey, subValue]) => (
                                                            <li key={subKey}>
                                                                <SectionTitle>
                                                                    {subKey.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase())}
                                                                </SectionTitle>
                                                                <div className="values">
                                                                    {Array.isArray(subValue)
                                                                        ? subValue.map((item, index) => <div key={index}>{item}</div>)
                                                                        : <div>{subValue}</div>}
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </li>
                                            ));
                                        }

                                        // Default rendering for other metadata fields
                                        return (
                                            <li key={key}>
                                                <SectionTitle>
                                                    {key.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase())}
                                                </SectionTitle>
                                                <div className="values">
                                                    {Array.isArray(value)
                                                        ? value.map((item, index) => <div key={index}>{item}</div>)
                                                        : <div>{value}</div>}
                                                </div>
                                            </li>
                                        );
                                    })}
                                </MetadataList>
                            </MetadataSection>
                        </SidebarContent>
                    )}
                </ResizableSidebar>
            )}
        </MainContainer>
    );
};

export default ChatbotRequestTable;
import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

const StripeConfirmation = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const verifySession = async () => {
      const sessionId = searchParams.get('session');

      if (!sessionId) {
        // If session_id is missing, redirect to login
        navigate('/login');
        return;
      }

      try {
        const response = await fetch('https://platform.iwander.io/external/verify-subscription', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ stripe_session: sessionId }),
        });

        const data = await response.json();

        if (response.ok) {
          // Store token and organization ID in storage
          localStorage.setItem('subscription_status', data.subscription_status);


          // Redirect to configuration page
          navigate('/configuration?tab=deployment');
        } else {
          // Redirect to login if verification fails
          console.error('Session verification failed:', data.detail || 'Unknown error');
          navigate('/login');
        }
      } catch (error) {
        console.error('Error verifying session:', error);
        navigate('/login');
      }
    };

    verifySession();
  }, [searchParams, navigate]);

  // Display a loading spinner
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <p>Verifying your session...</p>
    </div>
  );
};

export default StripeConfirmation;
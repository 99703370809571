import React from 'react';
import { Navigate } from 'react-router-dom';

// Protected Route component that checks if user is authenticated
const ProtectedRoute = ({ children }) => {
  const authToken = sessionStorage.getItem('authToken') || localStorage.getItem('authToken');
  const organisationId = sessionStorage.getItem('organisation_id') || localStorage.getItem('organisation_id');
  if (!authToken || authToken === "undefined" || !organisationId || organisationId === "null" || organisationId === "none") {
    // If no authToken, redirect to login
    console.log("No authToken found, redirecting to login...");
    return <Navigate to="/login" replace />;
  }

  // If authenticated, render the protected content
  return children;
};

export default ProtectedRoute;
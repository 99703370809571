import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";

const slideIn = keyframes`
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
`;

const NotificationBanner = styled.div`
  position: fixed;
  top: 5px;
  left: 40%;
  background-color: ${({ type }) => (type === "success" ? "#28a745" : "#dc3545")}; 
  color: white;
  padding: 15px;
  border-radius: 15px;
  text-align: center;
  font-size: 1rem;
  z-index: 1000;
  animation: ${slideIn} 0.3s ease-out;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

const Notification = ({ message, type, onRemove }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onRemove(); // Automatically remove the notification after 3 seconds
    }, 3000);

    return () => clearTimeout(timer); // Clear the timer if the component is unmounted
  }, [onRemove]);

  return <NotificationBanner type={type}>{message}</NotificationBanner>;
};

export default Notification;
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import LoadingSpinner from "../Loading";
import Notification from "../Notification";
import './ProductTable.css';
import { fadeIn } from "../Animations";

const MainContainer = styled.div`
  display: flex;
  height: 80vh;
  font-size: 14px;
  animation: 1s ${fadeIn} ease-out;
`;

const TableContainer = styled.div`
  flex: 4;
  overflow-x: auto;
`;

const StyledTable = styled.table`
  width: 100%;
  min-width: 1000px;
  border-collapse: collapse;
  text-align: left;

  thead {
    position: sticky;
    top: 0;
    z-index: 2;
  }

  th {
    padding: 12px 15px;
    height: 25px;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 275px;
    background-color: ${({ theme }) => theme.tableHeader};
    color: #a0a0ab;
    font-weight: 600;
    font-size: 12px;
    line-height: 1.25rem;
  }

  td {
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 275px;
    font-size: 12px;
    line-height: 1.25rem;
    padding: 1rem;
  }

  tr {
    &:hover {
      background-color: ${({ theme }) => theme.tableHeader};
    }
    border-bottom: 0.5px solid ${({ theme }) => theme.border};
  }
`;

const SearchAddContainer = styled.div`
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const SaveButton = styled.button`
  background-color: ${({ theme }) => theme.primary};
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.primaryHover};
  }
`;

const PartnerAPI = () => {
    const [notification, setNotification] = useState(null);
    const [notificationType, setNotificationType] = useState("success"); // Track notification type (success or error)
    const [loading, setLoading] = useState(false);
    const [preToolsSelected, setPreToolsSelected] = useState({});
    const [postToolsSelected, setPostToolsSelected] = useState({});
    const [searchQuery, setSearchQuery] = useState('');

    const fetchTools = async () => {
        setLoading(true);
        try {
            const response = await fetch('https://platform.iwander.io/agent_setup/get-partner-tools', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch partner tools');
            }

            const data = await response.json();
            setPreToolsSelected(data.pre_tools_selected || {});
            setPostToolsSelected(data.post_tools_selected || {});
        } catch (error) {
            console.error('Error fetching tools:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTools();
    }, []);

    const handleToggle = (toolType, toolName) => {
        if (toolType === 'pre') {
            setPreToolsSelected((prev) => ({
                ...prev,
                [toolName]: !prev[toolName],
            }));
        } else if (toolType === 'post') {
            setPostToolsSelected((prev) => ({
                ...prev,
                [toolName]: !prev[toolName],
            }));
        }
    };

    const handleSave = async () => {
        try {
            const payload = {
                pre_tools_selected: preToolsSelected,
                post_tools_selected: postToolsSelected,
            };

            const response = await fetch('https://platform.iwander.io/agent_setup/update-partner_tools', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                },
                body: JSON.stringify(payload),
            });

            if (response.ok) {
                setNotification("🚀 Changes saved successfully!");
                setNotificationType("success"); // Set notification type to success
            } else {
                setNotification("⚠️ Failed to save changes. Please try again.");
                setNotificationType("error"); // Set notification type to error
            }

            // alert("Configuration updated successfully!");
        } catch (error) {
            console.error("Error updating configuration:", error);
            alert("Failed to update configuration. Please try again.");
        }
    };

    const removeNotification = () => {
        setNotification(null); // Remove the notification
    };


        const filteredTools = [
            { name: 'get_sightseeing_tickets', displayName: 'My Products' },
            { name: 'get_tickets_points_of_interest', displayName: 'Viator' },
            { name: 'get_tickets_live_events', displayName: 'Ticketmaster' },
        ].filter((tool) => tool.displayName.toLowerCase().includes(searchQuery.toLowerCase()));

        return (
            <MainContainer>
                            {notification && (
                <Notification
                    message={notification}
                    type={notificationType} // Pass the notification type
                    onRemove={removeNotification}
                />
            )}

                <LoadingSpinner loading={loading} />
                <TableContainer>
                    <SearchAddContainer>
                        <input
                            type="text"
                            placeholder="Search affiliates..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            style={{
                                padding: '10px',
                                border: '1px solid #ccc',
                                borderRadius: '5px',
                                width: '300px',
                            }}
                        />
                        <SaveButton onClick={handleSave}>Save Changes</SaveButton>
                    </SearchAddContainer>

                    <StyledTable>
                        <thead>
                            <tr>
                                <th>Affiliate</th>
                                <th>Pre-booking</th>
                                <th>Post-booking</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredTools.map((tool) => (
                                <tr key={tool.name}>
                                    <td>{tool.displayName}</td>
                                    <td>
                                        <label className="switch">
                                            <input
                                                type="checkbox"
                                                checked={preToolsSelected[tool.name] || false}
                                                onChange={() => handleToggle('pre', tool.name)}
                                            />
                                            <span className="slider"></span>
                                        </label>
                                    </td>
                                    <td>
                                        <label className="switch">
                                            <input
                                                type="checkbox"
                                                checked={postToolsSelected[tool.name] || false}
                                                onChange={() => handleToggle('post', tool.name)}
                                            />
                                            <span className="slider"></span>
                                        </label>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </StyledTable>
                </TableContainer>
            </MainContainer>
        );
    };

    export default PartnerAPI;
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Sidebar from './Sidebar';
import Header from '../Header';
import styled from 'styled-components';

import TextGeneration from './TextGeneration';
import TextWebsSocket from './TextWebSocket';
import CreateUser from './CreateUser';
import DeleteUser from './DeleteUser';
import UpdatePreferences from './UpdatePreferences';
import ImageRecognition from './ImageRecognition';
import RecommendedStops from './RecommendedStops';
import UserSessionByID from './UserSessionByID';
import UserSessions from './UserSessions';
import Text2Speech from './Text2Speech';
import Text2SpeechStream from './Text2SpeechStream';
import AudioWebsocket from './AudioWebsocket';
import GetGuides from './GetGuides';
import GetVoices from './GetVoices';
import ApiDocumentation from './Introduction';
import Tickets from './Tickets';

const AppContainer = styled.div`
  display: flex;
  margin-top: 60px;
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  overflow: auto;
`;

function DocsLayout({ toggleTheme, theme }) {
  return (
    <>
      <Header toggleTheme={toggleTheme} theme={theme} />
      <AppContainer>
        <Sidebar />
        <ContentContainer>
          <Routes>
            <Route path="/text-generation" element={<TextGeneration />} />
            <Route path="/create-user" element={<CreateUser />} />
            <Route path="/delete-user" element={<DeleteUser />} />
            <Route path="/update-user-preferences" element={<UpdatePreferences />} />
            <Route path="/image-recognition" element={<ImageRecognition />} />
            <Route path="/recommended-next-stops" element={<RecommendedStops />} />
            <Route path="/user-sessions" element={<UserSessions />} />
            <Route path="/user-session-by-id" element={<UserSessionByID />} />
            <Route path="/audio-generation" element={<Text2Speech />} />
            <Route path="/audio-generation-stream" element={<Text2SpeechStream />} />
            <Route path="/text-generation-websocket" element={<TextWebsSocket />} />
            <Route path="/audio-generation-websocket" element={<AudioWebsocket />} />
            <Route path="/tickets" element={<Tickets />} />
            <Route path="/guides" element={<GetGuides />} />
            <Route path="/voices" element={<GetVoices />} />
            <Route path="*" element={<ApiDocumentation />} />
          </Routes>
        </ContentContainer>
      </AppContainer>
    </>
  );
}

export default DocsLayout;
import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import './ProductTable.css';
import AddProductForm from './AddProductForm';
import ProductEditModal from './ProductEditModal';
import LoadingSpinner from '../Loading';
import { fadeIn, fadeOut } from "../Animations";

const MainContainer = styled.div`
  gap: 20px;
  height: 80vh;
  font-size: 14px;
  position: relative;
  animation: 1s ${fadeIn} ease-out;
`;

const TableContainer = styled.div`
  flex: 4;
  overflow-x: auto; /* Enables horizontal scrolling */
  margin-top: 20px;
`;

const StyledTable = styled.table`
  width: 100%;
  min-width: 1000px; /* Ensures table doesn't shrink below this width */
  border-collapse: collapse;
  text-align: left;

  thead {
    position: sticky;
    top: 0; /* Sticks the header to the top of the table */
    z-index: 2; 
  }

  th {
    padding: 12px 15px;
    height: 25px; /* Ensures uniform row height */
    vertical-align: middle; /* Align text vertically to the middle */
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflowing content */
    text-overflow: ellipsis; /* Add ellipsis to the end of the content */
    max-width: 275px; /* Adjust width for ellipsis effect */
    background-color: ${({ theme }) => theme.tableHeader};
    color: #a0a0ab;
    font-weight: 600;
    font-size: 12px;
    line-height: 1.25rem;
  }

  td {
    vertical-align: middle; 
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
    max-width: 275px; 
    font-size: 12px;
    line-height: 1.25rem;
    padding: 1rem;
    cursor: pointer;
  }

  tr {
    &:hover {
      background-color: ${({ theme }) => theme.tableHeader};
    }
    border-bottom: 0.5px solid ${({ theme }) => theme.border};
  }
`;

const SearchAddContainer = styled.div`
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const SearchInput = styled.input`
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 5px;
  width: 300px;
  background-color: ${({ theme }) => theme.inputBg};
  color: ${({ theme }) => theme.text};
`;

const AddButton = styled.button`
  background-color: ${({ theme }) => theme.primary};
  color: white;
  padding: 10px 15px;
  margin-right: 10px;
  border: none;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.primary};
  }
`;

const ProductTable = () => {
    const [products, setProducts] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [editingProductId, setEditingProductId] = useState(null);
    const [editedProduct, setEditedProduct] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem("authToken");

    const fetchProducts = async () => {
        setLoading(true);
        try {
            const response = await fetch('https://platform.iwander.io/products/get-products', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = await response.json();
            setProducts(data);
        } catch (error) {
            console.error('Error fetching products', error);
        } finally {
            setLoading(false); // Stop loading
        }
    };

    useEffect(() => {
        fetchProducts();
    }, []);


    useEffect(() => {
        const handleEsc = (event) => {
            if (event.key === 'Escape') {
                setIsAddModalOpen(false);
                setIsModalOpen(false);
            }
        };

        if (isModalOpen || isAddModalOpen) {
            window.addEventListener('keydown', handleEsc);
        }

        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, [isModalOpen, isAddModalOpen]);

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleRowClick = (product) => {
        setEditedProduct(product);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setEditedProduct({});
    };

    const handleSaveClick = async () => {
        const response = await fetch('https://platform.iwander.io/products/update-product', {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                product_id: editedProduct._id,
                title: editedProduct.title,
                description: editedProduct.description,
                image_url: editedProduct.image_url,
                location: editedProduct.location,
                product_url: editedProduct.product_url,
            })
        });

        if (response.ok) {
            setProducts(products.map(p => (p._id === editedProduct._id ? editedProduct : p)));
            setIsModalOpen(false);
            setEditingProductId(null);
            setEditedProduct({});
        } else {
            alert('Failed to update product');
        }
    };

    const handleCreateProduct = async (newProduct) => {
        const response = await fetch('https://platform.iwander.io/products/create-product', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(newProduct)
        });

        if (response.ok) {
            const createdProduct = await response.json();
            setProducts([...products, createdProduct]);
            setIsAddModalOpen(false);
        } else {
            alert('Failed to create product');
        }
    };

    const handleProductChange = (e) => {
        const { name, value } = e.target;
        setEditedProduct((prevProduct) => ({
          ...prevProduct,
          [name]: value, // Update the specific field
        }));
      };
      
    const handleOpenAddModal = () => {
        setIsAddModalOpen(true);
    };

    const handleCloseAddModal = () => {
        setIsAddModalOpen(false);
    };


    const filteredProducts = products.filter((product) =>
        product.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <MainContainer>
            <LoadingSpinner loading={loading} />
                <SearchAddContainer>
                    <SearchInput
                        type="text"
                        placeholder="Search products by name..."
                        value={searchQuery}
                        onChange={handleSearch}
                    />
                    <AddButton onClick={handleOpenAddModal}> Add Products</AddButton>
                </SearchAddContainer>
            <TableContainer>

                <StyledTable>
                    <thead>
                        <tr>
                            <th>Image</th>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Location</th>
                            <th>Link</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredProducts.map((product) => (
                            <tr key={product._id} onClick={() => handleRowClick(product)}>
                                <td>
                                    <img src={product.image_url} alt={product.title} style={{ width: '50px', borderRadius: '5px' }} />
                                </td>
                                <td>{product.title}</td>
                                <td>{product.description}</td>
                                <td>{product.location}</td>
                                <td>
                                    <a href={product.product_url} target="_blank" rel="noopener noreferrer"
                                        onClick={(event) => event.stopPropagation()} >
                                        View Product
                                    </a>
                                </td>
                            </tr>
                        ))}
                    </tbody>

                </StyledTable>
            </TableContainer>

            {isModalOpen && (
                <ProductEditModal
                    product={editedProduct}
                    onSave={handleSaveClick}
                    onCancel={handleCloseModal}
                    onChange={handleProductChange}
                />
            )}

            {isAddModalOpen && (
                <AddProductForm
                    handleCreateProduct={handleCreateProduct}
                    onCancel={handleCloseAddModal}
                />
            )}
        </MainContainer>
    );
};

export default ProductTable;
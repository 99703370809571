import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './AddProductForm.css';

const AddProductForm = ({ handleCreateProduct, onCancel }) => {
  const [newProduct, setNewProduct] = useState({
    title: '',
    description: '',
    image_url: '',
    product_url: '',
    location: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewProduct({ ...newProduct, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleCreateProduct(newProduct);
    // Reset the form after creation
    setNewProduct({
      title: '',
      description: '',
      image_url: '',
      product_url: '',
      location: ''
    });
    // Close the modal after submission
    onCancel();
  };

  return (
    <div className="modal">
      <div className="modal-box-content">
        <div className="modal-close-button" onClick={onCancel}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
        <h2>Add Product</h2>
        <form onSubmit={handleSubmit}>
          <div className="edit-field">
            <label>Name:</label>
            <textarea
              name="title"
              value={newProduct.title}
              onChange={handleChange}
              required
            />
          </div>
          <div className="edit-field">
            <label>Description:</label>
            <textarea
              name="description"
              value={newProduct.description}
              onChange={handleChange}
              required
            />
          </div>
          <div className="edit-field">
            <label>Location:</label>
            <textarea
              name="location"
              value={newProduct.location}
              onChange={handleChange}
              required
            />
          </div>
          <div className="edit-field">
            <label>Image URL:</label>
            <textarea
              name="image_url"
              value={newProduct.image_url}
              onChange={handleChange}
              required
            />
          </div>
          <div className="edit-field">
            <label>Product URL:</label>
            <textarea
              name="product_url"
              value={newProduct.product_url}
              onChange={handleChange}
              required
            />
          </div>
          <div className="modal-actions">
          <button type="button" onClick={onCancel}>Cancel</button>
            <button className="save-button" type="submit">Save</button>
          </div>

        </form>
      </div>
    </div>
  );
};

export default AddProductForm;
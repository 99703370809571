import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { fadeIn } from "../Animations";

const PageContainer = styled.div`
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: -webkit-fill-available;
  color: ${({ theme }) => theme.text};
  font-size: 14px;
  overflow: hidden;
  animation: 1s ${fadeIn} ease-out;
`;

const Column = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
`;

const SubscriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  background-color: ${({ theme }) => theme.cardBackground};
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px; /* Limit the width for better UI */
`;

const SectionTitle = styled.h3`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
  color: ${({ theme }) => theme.primary};
`;

const Subtitle = styled.p`
  font-size: 14px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.secondaryText};
`;

const SubscribeButton = styled.button`
  background-color: ${({ theme }) => theme.primary};
  color: white;
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    background-color: ${({ theme }) => theme.primaryHover};
  }
`;


const CodeBlock = styled.pre`
  background-color: ${({ theme }) => theme.inputBg};
  color: ${({ theme }) => theme.text};
  padding: 15px;
  border-radius: 10px;
  font-size: 12px;
  overflow-x: auto;
  white-space: pre-wrap;
`;

const DeployChatbotPage = () => {
  const navigate = useNavigate();
  const subscriptionStatus = localStorage.getItem("subscription_status");
  const orgId = localStorage.getItem("organisation_id");
  const [isLoading, setIsLoading] = useState(false);


  const handleSubscribe = async () => {
    const token = localStorage.getItem("authToken");
    setIsLoading(true);

    try {
      const response = await fetch("https://platform.iwander.io/external/subscription-reference", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to retrieve subscription reference.");
      }

      const data = await response.json();
      const stripeUrl = `https://buy.stripe.com/test_eVa9Dc9S43wJfksfYY?prefilled_email=${encodeURIComponent(
        data.user_email
      )}&client_reference_id=${data.user_id}`;

      // Redirect to Stripe
      window.location.href = stripeUrl;
    } catch (error) {
      console.error("Error managing subscription:", error);
      alert("Failed to manage subscription. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  if (subscriptionStatus !== "active") {
    return (
      <PageContainer>
        <SubscriptionContainer>
          <SectionTitle>Unlock Access to Deployment Features</SectionTitle>
          <Subtitle>
            To deploy the chatbot and enjoy all the benefits of our platform, please subscribe.
          </Subtitle>
          <SubscribeButton onClick={handleSubscribe}>Subscribe Now</SubscribeButton>
        </SubscriptionContainer>
      </PageContainer>
    );
  }


  return (
    <PageContainer>
      {/* Guide Column */}
      <Column>
        <SectionTitle>How to Deploy the Chatbot</SectionTitle>
        <p>Follow these simple steps to integrate your chatbot:</p>
        <ol>
          <li>Copy the script provided below.</li>
          <li>Paste it into the HEAD section of your website's HTML.</li>
          <li>Save the changes and refresh your website to activate the chatbot.</li>
        </ol>
        <CodeBlock>
          {`<script src="https://platform.iwander.io/pre-book-widget.js?id=${orgId}"></script>`}
        </CodeBlock>
        <CodeBlock>
          {`<script src="https://platform.iwander.io/post-book-widget.js?id=${orgId}"></script>`}
        </CodeBlock>
      </Column>
    </PageContainer>
  );
};

export default DeployChatbotPage;